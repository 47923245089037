import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { initializeTelemetry } from "app/state/slices/telemetrySlice";
import { setSelectedModal, showCustomLoadingModal } from "app/state/slices/ifcManagerSlice";
import { MODALS, QUICKSTART_SECTIONS } from "app/common/types";
import FullTermsAccountPage from "app/pages/FullTermsAccountPage";
import ViewerPage from "app/pages/ViewerPage";
import FullTermsOpenPage from "app/pages/FullTermsOpenPage";
import { firebase } from "app/utils/firebase";
import { initAccounts, updateAccount } from "app/state/slices/ifcManagerSlice";
import { useAppDispatch } from "app/state/hooks";
import { useParamURLFileLoad } from "app/components/common/useFileLoad";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import QuickstartPage from "app/pages/QuickstartPage";

if (import.meta.env.MODE == "dev") {
  console.log("registering window.onerror");
  window.onerror = (a, b, c, d, e) => {
    // note: this is for locally debugging Sentry errors which don't get shown in the console
    console.log(`message: ${a}`);
    console.log(`source: ${b}`);
    console.log(`lineno: ${c}`);
    console.log(`colno: ${d}`);
    console.error(e);

    return true;
  };
}

export default function App() {
  const dispatch = useAppDispatch();
  const paramUrlFileLoad = useParamURLFileLoad();

  useEffect(() => {
    const hasAcceptedToS = localStorage.getItem("acceptedToS") === "true";
    if (!hasAcceptedToS) {
      dispatch(setSelectedModal(MODALS.OPENAGREEMENT));
    }

    const hasAcceptedTelemetry = localStorage.getItem("acceptedTelemetry") === "true";
    if (hasAcceptedTelemetry) {
      dispatch(initializeTelemetry());
    }

    dispatch(initAccounts());

    firebase.onAuthStateChanged(
      firebase.auth,
      () => (console.log("onAuthStateChanged"), dispatch(updateAccount()))
    );

    if (hasAcceptedToS) {
      paramUrlFileLoad();

      // start the autodesk flow, next step is in updateAccount
      const code = new URLSearchParams(window.location.search).get("code");
      if (code) {
        dispatch(showCustomLoadingModal("Linking with Autodesk Construction Cloud..."));
      }
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Routes>
        <Route path="/" element={<ViewerPage />} />
        <Route path="/full-terms-account" element={<FullTermsAccountPage />} />
        <Route path="/full-terms-open" element={<FullTermsOpenPage />} />
        <Route path="/quickstart" element={<QuickstartPage section={QUICKSTART_SECTIONS.HOME} />} />
        <Route
          path="/section-tool"
          element={<QuickstartPage section={QUICKSTART_SECTIONS.SECTION} />}
        />
        <Route
          path="/dimension-tool"
          element={<QuickstartPage section={QUICKSTART_SECTIONS.DIMENSION} />}
        />
        <Route
          path="/plans-tool"
          element={<QuickstartPage section={QUICKSTART_SECTIONS.PLAN_MANAGER} />}
        />
        <Route
          path="/query-tool"
          element={<QuickstartPage section={QUICKSTART_SECTIONS.QUERY} />}
        />
        <Route
          path="/object-tree-tool"
          element={<QuickstartPage section={QUICKSTART_SECTIONS.OBJECTTREE} />}
        />
        <Route
          path="/ids-tool"
          element={<QuickstartPage section={QUICKSTART_SECTIONS.IDSEDITOR} />}
        />
        <Route
          path="/properties-tool"
          element={<QuickstartPage section={QUICKSTART_SECTIONS.PROPERTIES} />}
        />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </LocalizationProvider>
  );
}
