import { useAppDispatch } from "app/state/hooks";
import { idsSpecLoadIDSFile } from "app/state/slices/ifcManager/idsEditor";
import { loadIfcFile } from "app/state/slices/ifcManagerSlice";
import { ChangeEvent } from "react";

export const useIDSFileLoad = () => {
  const dispatch = useAppDispatch();

  return async (e: ChangeEvent<HTMLInputElement>) => {
    console.log("useIDSFileLoad:", e);
    const files = e.target.files;
    if (!files || !files[0]) {
      e.target.value = "";
      e.target.files = null;
      return;
    }
    for (const file of files) {
      console.log("useIDSFileLoad: dispatch(idsSpecLoadIDSFile)");
      await dispatch(idsSpecLoadIDSFile({ file: file }));
      e.target.value = "";
      e.target.files = null;
      return;
    }
  };
};

export const useFileLoad = (isPartial?: boolean) => {
  const dispatch = useAppDispatch();

  return async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    if (!files || !files[0]) {
      return;
    }
    for (const file of files) {
      await dispatch(loadIfcFile({ file: file, isPartial }));
      // if multiple files are selected isPartial must be true for rest
      isPartial = true;
    }
  };
};

export const useURLFileLoad = (isPartial?: boolean) => {
  const dispatch = useAppDispatch();

  return (value: string) => {
    dispatch(loadIfcFile({ url: value, isPartial }));
  };
};

export const useParamURLFileLoad = () => {
  const dispatch = useAppDispatch();

  return () => {
    const url = new URLSearchParams(window.location.search).get("modelUrl");
    const sampleName = new URLSearchParams(window.location.search).get("sample");
    if (url) {
      console.log(`Loading file by param url:'${url}'`);
      dispatch(loadIfcFile({ url }));
    } else if (sampleName) {
      console.log(`Loading file by param directUrl:'/samples/${sampleName}'`);
      dispatch(loadIfcFile({ directUrl: `/samples/${sampleName}` }));
    }
  };
};
