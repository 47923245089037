import { createSlice } from "@reduxjs/toolkit";

import {
  IModelState,
  initialModelState,
  modelSelectors,
  modelReducers,
  modelThunks,
} from "./ifcManager/model";
import {
  ICloudState,
  initialCloudState,
  cloudSelectors,
  cloudReducers,
  cloudThunks,
  cloudMutationThunks,
} from "./ifcManager/cloud";
import { IPlansState, initialPlansState, plansReducers, plansSelectors } from "./ifcManager/plans";
import {
  IDimensionsState,
  initialDimensionsState,
  dimensionsSelectors,
  dimensionsReducers,
} from "./ifcManager/dimensions";
import {
  IPropertiesState,
  initialPropertiesState,
  propertiesReducers,
  propertiesThunks,
} from "./ifcManager/properties";
import {
  ISectionsState,
  initialSectionsState,
  sectionsSelectors,
  sectionsReducers,
} from "./ifcManager/sections";
import { IToolsState, initialToolsState, toolsReducers, toolsSelectors } from "./ifcManager/tools";
import {
  ICameraState,
  initialCameraState,
  cameraSelectors,
  cameraReducers,
} from "./ifcManager/camera";
import {
  IPopupsState,
  initialPopupsState,
  popupsReducers,
  popupsSelectors,
} from "./ifcManager/popups";
import { commonReducers } from "./ifcManager/common";
import {
  IIDSEditorState,
  idsEditorMutationThunks,
  idsEditorReducers,
  idsEditorSelectors,
  idsEditorThunks,
  initialIDSEditorState,
} from "./ifcManager/idsEditor";

export interface IIfcMangerState
  extends ICloudState,
    ISectionsState,
    IPlansState,
    IDimensionsState,
    IModelState,
    ICameraState,
    IPropertiesState,
    IToolsState,
    IPopupsState,
    IIDSEditorState {}

export const initialState: IIfcMangerState = {
  ...initialCameraState,
  ...initialCloudState,
  ...initialDimensionsState,
  ...initialModelState,
  ...initialPlansState,
  ...initialPopupsState,
  ...initialPropertiesState,
  ...initialSectionsState,
  ...initialToolsState,
  ...initialIDSEditorState,
};

export const ifcViewerSlice = createSlice({
  name: "ifcManager",
  initialState,
  reducers: {
    ...commonReducers,
    ...cameraReducers,
    ...cloudReducers,
    ...dimensionsReducers,
    ...modelReducers,
    ...plansReducers,
    ...propertiesReducers,
    ...sectionsReducers,
    ...toolsReducers,
    ...popupsReducers,
    ...idsEditorReducers,
  },
  extraReducers: builder => {
    const addThunksMutable = (thunks: object) => {
      Object.entries(thunks).forEach(([name, thunk]) => {
        builder.addCase(thunk.fulfilled, (state, { payload }) => {
          return {
            ...state,
            ...payload,
          };
        });
        builder.addCase(thunk.rejected, (state, { action, payload }) => {
          console.error(`thunk ${name} failed:`, action, payload);
          return {
            ...state,
            ...payload,
          };
        });
      });
    };

    const addThunksMutations = (thunks: object) => {
      Object.entries(thunks).forEach(([name, thunk]) => {
        builder.addCase(thunk.fulfilled, (state, { payload }) => {
          if (payload) payload(state);
        });
        builder.addCase(thunk.rejected, (state, { action, payload }) => {
          console.error(`thunk ${name} failed:`, action, payload);
          if (payload) payload(state);
        });
      });
    };

    addThunksMutable({
      ...cloudThunks,
      ...modelThunks,
      ...idsEditorThunks,
      ...propertiesThunks,
    });

    addThunksMutations({
      ...cloudMutationThunks,
      ...idsEditorMutationThunks,
    });
  },
});

/* cloud account */
export const {
  selectLoginName,
  selectLoginEmail,
  selectLoginPassword,
  selectLoginPasswordConfirmation,
  selectLoginNewPasswordConfirmation,
  selectLoginNewPassword,
  selectIsLoginPassMatch,
  selectIsNewPasswordStrong,
  selectIsLoginSignInActive,
  selectIsLoginSignUpActive,
  selectIsEmailConfirmed,
  selectAreChangesCloudSynced,
  selectQueryModelCloudId,
  selectModelCloudId,
  selectAccountFirstName,
  selectAccountLastName,
  selectAccountName,
  selectAccountIsLoggedIn,
  selectSavedModels,
  selectCloudTotalSize,
  selectCloudStorageQuota,
  selectAuthProvider,
  selectIsServerNotReachable,
  selectActiveAccountField,
  selectIsWaitingServerResponse,
  selectIsWaitingForUpload,
  selectIsWaitingForModelIdsDeletion,
  selectIsWaitingForModelQuery,
  selectQueryPollRetriesRemaining,
  selectWantsViewerNews,
  selectAutodeskEntities,
  selectAutodeskPendingUpdates,
} = cloudSelectors;
export const {
  initAccounts,
  updateLoginDetails,
  setIsWaitingServerResponseAccount,
  setIsWaitingForUpload,
  setIsWaitingForModelIdDeletion,
  invalidateCloudState,
  setIsWaitingForModelSchema,
  setIsWaitingForModelQuery,
  setQueryModelCloudId,
  setlastSignUpEventSource,
  setWantsViewerNews,
  setAutodeskPendingUpdate,
} = ifcViewerSlice.actions;
export const {
  listModels,
  saveCloudState,
  uploadModel,
  loginWithEmail,
  registerWithEmail,
  loginWithMicrosoft,
  loginWithGoogle,
  updatePassword,
  sendResetPasswordEmail,
  relogin,
  saveAccountChanges,
  checkServerConnection,
  setActiveAccountField,
  deleteAccount,
  accountSignOut,
  queryFileSchema,
  queryFile,
  updateAccount,
} = cloudThunks;
export const {
  deleteModel,
  autodeskPopulateChildren,
  autodeskSyncFile,
  autodeskLinkAccount,
  autodeskLinkRedirect,
} = cloudMutationThunks;

/* tools */
export const {
  selectIsToolSelected,
  selectIsToolActive,
  selectIsNavGizmoEnabled,
  selectSelectedTools,
  selectActiveTools,
  selectIsLeftPanelOpen,
  selectIsRightPanelOpen,
} = toolsSelectors;
export const { setTool, hideTool, toggleTool } = ifcViewerSlice.actions;

/* camera */
export const { selectIsOrthoEnabled } = cameraSelectors;
export const { toggleOrtho } = ifcViewerSlice.actions;

/* dimensions */
export const { selectDimensions, selectIsMeasurementCreationActive } = dimensionsSelectors;
export const {
  createOrFinishMeasurement,
  removeDimension,
  setIsDimensionHighlighted,
  setIsDimensionTempHighlighted,
  unhighlightAllDimensions,
  setDimensionVisibility,
  removeAllDimensions,
  startMeasurementCreation,
  cancelMeasurementCreation,
} = ifcViewerSlice.actions;

/* file/model loading */
export const {
  selectModelId,
  selectModelUUID,
  selectModelsDetails,
  selectFileName,
  selectIsViewerDisplayed,
  selectIsFirstLoad,
  selectIsModelReadyToQuery,
  selectModelQueryTypes,
  selectModelQuerySchema,
  selectModelQueryResult,
  selectModelQueryExportedAttributes,
  selectModelQuerySelectedTypes,
} = modelSelectors;
export const {
  setFileName,
  unloadAll,
  unloadOne,
  setIsViewerDisplayed,
  setModelQueryExportedAttributes,
  setModelQuerySelectedTypes,
} = ifcViewerSlice.actions;
export const { loadIfcFile, exportModelQuery } = modelThunks;

/* plans */
export const { selectPlans, selectSelectedPlan } = plansSelectors;
export const { goToPlan, deselectPlan } = ifcViewerSlice.actions;

/* IDS Editor */
export const {
  selectIDSSpecSelectedId,
  selectIDSSpecs,
  selectIDSSpecAttr,
  selectIDSSpec,
  selectIDSRestriction,
  selectIDSSpecSelectedRestrictionPath,
  selectIDSResultItem,
  selectIDSPanelState,
  selectIDSPanelSection,
  selectIDSInfo,
  selectIDSParsingError,
  selectIDSFileName,
  selectIDSMode,
  selectIsIDSValidationRunning,
  selectIDSApplAttr,
  selectIDSReqsAttr,
  selectIDSSpecsOrder,
  selectRestrictionPathHasError,
} = idsEditorSelectors;
export const {
  idsSpecLoadIDSFile,
  idsSpecDownloadIDSFile,
  idsSpecDownloadSpecsCSVFile,
  idsSpecHighlightResultItem,
} = idsEditorThunks;
export const { idsSpecRunValidation } = idsEditorMutationThunks;
export const {
  createNewIDSFile,
  idsSetFileName,
  idsSpecSetSelectedId,
  createIDSSpec,
  deleteIDSSpec,
  duplicateIDSSpec,
  moveToIDSSpec,
  moveToIDSResult,
  moveToIDSRestriction,
  moveToIDSSection,
  idsSpecUpdateAttr,
  idsSpecAddApplicabilityRestriction,
  idsSpecAddRequirementsRestriction,
  idsSpecSetSelectedRestrictionPath,
  idsSpecDeleteRestriction,
  idsSpecChangeRestrictionFacet,
  idsSpecUpdateRestriction,
  idsSpecChangeRestrictionType,
  idsSetPanelState,
  idsSetPanelSection,
  idsSetInfo,
  idsResetParsingError,
  idsSpecSetSelectedRestrictionXPath,
  idsForceFullNormalisation,
  idsSetMode,
  idsApplUpdateAttr,
  idsReqsUpdateAttr,
  idsFixUppercaseBooleans,
} = ifcViewerSlice.actions;

/* popups */
export const {
  selectSelectedPolicy,
  selectSelectedModal,
  selectPreviousModal,
  selectSnackbarMessage,
  selectSnackbarSeverity,
  selectLoginMessage,
  selectLoginSeverity,
  selectLoadingModalMessage,
} = popupsSelectors;
export const {
  setSelectedPolicyModal,
  setSelectedModal,
  dismissModal,
  dismissSnackbar,
  dismissLoginAlert,
  showCustomLoadingModal,
} = ifcViewerSlice.actions;

/* properties */
export {
  selectPropertiesExpressID,
  selectpropertiesModelUUID,
  selectLastSelectedPropertyName,
  selectPropertiesSelectedSetName,
  selectPropertiesItemName,
} from "./ifcManager/properties";
export const {
  setLastSelectedPropertyName,
  pickIFCItemProps,
  setPropertiesSelectedSetName,
  setPropertiesItemName,
} = ifcViewerSlice.actions;
export const { highlightByID, setVisibilityByID } = propertiesThunks;

/* sections */
export const { selectIsSectionCreationActive, selectCustomSections } = sectionsSelectors;
export const {
  startSectionCreation,
  cancelSectionCreation,
  createSection,
  deleteSection,
  setIsSectionEditable,
  setSectionName,
  setIsSectionVisibilityLocked,
  hideAllSections,
  updateSectionPosition,
} = ifcViewerSlice.actions;

export default ifcViewerSlice.reducer;
