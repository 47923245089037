import CloseIcon from "@mui/icons-material/Close";
import CloudIcon from "@mui/icons-material/Cloud";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { AutodeskEntity, getAutodeskEntityHandle, MODALS } from "app/common/types";
import { BoxRow } from "app/components/common/BoxRow";
import { Heavy } from "app/components/common/Heavy";
import InlineIcon from "app/components/common/InlineIcon";
import { LinkArrow } from "app/components/common/LinkArrow";
import { LocalIFCPicker } from "app/components/common/LocalIFCPicker";
import { useFileLoad } from "app/components/common/useFileLoad";
import FillFlexParent from "app/components/FillFlexParent";
import { ToolsListItem } from "app/components/ToolsSideBar/common";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import { confirmEmail } from "app/state/slices/ifcManager/cloud";
import {
  autodeskLinkRedirect,
  autodeskPopulateChildren,
  autodeskSyncFile,
  deleteModel,
  dismissModal,
  listModels,
  loadIfcFile,
  selectAccountIsLoggedIn,
  selectAreChangesCloudSynced,
  selectAutodeskEntities,
  selectAutodeskPendingUpdates,
  selectCloudStorageQuota,
  selectCloudTotalSize,
  selectFileName,
  selectIsEmailConfirmed,
  selectIsWaitingForModelIdsDeletion,
  selectIsWaitingForUpload,
  selectIsWaitingServerResponse,
  selectModelCloudId,
  selectSavedModels,
  selectSelectedModal,
  setIsWaitingServerResponseAccount,
  uploadModel,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import { memo, useEffect, useRef, useState } from "react";
import { NodeRendererProps, Tree, TreeApi } from "react-arborist";

type AutodeskNode = {
  name: string;
  id: string;
  children?: AutodeskNode[];
  metadata: AutodeskEntity & {
    isPlaceholder: boolean;
    placeholderType?: "loading";
  };
};

const autodeskUITypeNames: Record<string, string> = {
  hubs: "hub",
  projects: "project",
  folders: "folder",
  files: "file",
  items: "item",
};

const nodeHeight = 6 * theme.sp.compactH;

const CustomNode = ({ node, style, dragHandle }: NodeRendererProps<AutodeskNode>) => {
  const dispatch = useAppDispatch();

  const isEmptyPlaceHolder =
    node.data.metadata.isPlaceholder &&
    node.data.metadata.placeholderType == "loading" &&
    node.data.metadata.type != "items";

  return (
    <div
      ref={dragHandle}
      id="selectableTreeItem"
      className="TreeItemHover"
      style={{
        ...style,
        height: `${nodeHeight}px`,
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderRadius: "8px",
        marginRight: "8px",
        border: "transparent 2px solid",
        ...(node.isSelected && {
          backgroundColor: theme.palette.selectedBackground,
          border: "#0d47a1 2px solid",
        }),
      }}
    >
      {node.data.metadata.type == "items" && (
        <FiberManualRecordIcon
          sx={{
            color: theme.palette.softBulletGrey,
            padding: `${theme.sp.compactH + theme.sp.gapTiny}px`,
            margin: `0 ${theme.sppx.compactV}`,
            width: `${theme.sppx.compact2H}`,
            height: `${theme.sppx.compact2H}`,
          }}
        />
      )}
      {isEmptyPlaceHolder && (
        <CircularProgress
          size={"1.5em"}
          sx={{
            color: theme.palette.softBulletGrey,
            padding: `${theme.sp.compactH + theme.sp.gapTiny}px`,
            margin: `0 ${theme.sppx.compactV}`,
          }}
        />
      )}
      {!node.data.metadata.isPlaceholder && node.data.metadata.type != "items" && (
        <Tooltip placement="top" arrow title="Toggle Expansion">
          <IconButton
            color={node.isOpen ? "primary" : "default"}
            onClick={e => {
              e.stopPropagation();
              node.toggle();
              if (node.isOpen) {
                dispatch(
                  autodeskPopulateChildren({
                    entityHandle: getAutodeskEntityHandle(node.data.metadata),
                  })
                );
              }
            }}
            variant="inline"
            sx={{
              padding: `${theme.sppx.compactH}`,
              marginLeft: theme.sppx.gapTiny,
              marginRight: theme.sppx.gapTiny,
            }}
          >
            {node.isOpen ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </Tooltip>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          "&:hover": {
            overflowX: "auto",
            textOverflow: "inherit",
          },
        }}
      >
        <Typography>{node.data.name}</Typography>
        {!node.data.name && !node.data.metadata.isPlaceholder && (
          <Typography sx={{ color: theme.palette.inactiveGrey, fontStyle: "italic" }}>
            Unnamed
          </Typography>
        )}
        {isEmptyPlaceHolder && (
          <Typography sx={{ color: theme.palette.inactiveGrey, fontStyle: "italic" }}>
            Loading...
          </Typography>
        )}
        {node.data.name && (
          <Typography sx={{ color: theme.palette.inactiveGrey }}>
            {autodeskUITypeNames[node.data.metadata?.type ?? ""]}
          </Typography>
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }} />

      {!node.data.metadata.isPlaceholder && node.data.metadata.type == "items" && (
        <Tooltip placement="top" arrow title="Import">
          <IconButton
            color="primary"
            onClick={e => {
              e.stopPropagation();
              dispatch(
                autodeskSyncFile({
                  entityHandle: node.data.metadata,
                })
              );
            }}
            variant="inline"
            sx={{
              padding: `${theme.sppx.compactH}`,
              marginLeft: theme.sppx.gapTiny,
              marginRight: theme.sppx.gapTiny,
            }}
          >
            <FileUploadIcon />
          </IconButton>
        </Tooltip>
      )}
      {node.data.metadata.isPlaceholder && node.data.metadata.type == "items" && (
        <Tooltip placement="top" arrow title="Importing..">
          <CircularProgress size="1.5em" sx={{ marginRight: theme.sppx.compact2H }} />
        </Tooltip>
      )}
    </div>
  );
};

function AutodeskTree({
  width,
  height,
  searchText,
}: {
  width: number;
  height: number;
  searchText: string;
}) {
  const [tree, setTree] = useState([] as AutodeskNode[]);
  const treeAPI = useRef<TreeApi<AutodeskNode>>();

  const autodeskEntities = useAppSelector(selectAutodeskEntities);
  const autodeskPendingUpdates = useAppSelector(selectAutodeskPendingUpdates);

  const expandEntities = (ids: string[]) => {
    if (!ids) return [];
    const results: AutodeskNode[] = [];

    for (const id of ids) {
      const entity = autodeskEntities[id];
      const children: AutodeskNode[] = [];
      const hasPendingUpdate = autodeskPendingUpdates[id] != null;
      const isItemPendingUpdate = hasPendingUpdate && entity.type == "items";

      if (hasPendingUpdate && entity.type != "items") {
        children.push({
          id: "spinner" + id,
          name: "",
          metadata: {
            isPlaceholder: true,
            placeholderType: "loading",
            children: [],
          },
          children: [],
        });
      }

      results.push({
        id: entity.id ?? "",
        name: entity.displayName || entity.name || "",
        metadata: {
          ...entity,
          isPlaceholder: isItemPendingUpdate,
          ...(isItemPendingUpdate && {
            placeholderType: "loading",
          }),
        },
        children: children.concat(
          entity.children.length > 0 ? expandEntities(entity.children) : []
        ),
      });
    }

    // empty roots
    if (ids.length == 0) {
      const keys = Object.keys(autodeskPendingUpdates);
      if (keys.length > 0) {
        const rootKey = keys[0];

        results.push({
          id: "spinner" + rootKey,
          name: "",
          metadata: {
            isPlaceholder: true,
            placeholderType: "loading",
            children: [],
          },
        });
      }
    }

    return results;
  };

  useEffect(() => {
    const roots = Object.values(autodeskEntities).filter(x => x.type == "hubs");
    if (roots) {
      setTree(expandEntities(roots.map(x => x.id ?? "")));

      if (roots.length > 0) {
        // note: the tree doesn't update after adding roots
        // so this piece of code forces an update of new nodes
        setTimeout(() => {
          for (const node of roots) {
            const id = node.id;
            if (id && !treeAPI.current?.isOpen(id)) {
              treeAPI.current?.open(id);
              treeAPI.current?.close(id);
            }
          }
        }, 0);
      }
    }
  }, [treeAPI.current, autodeskEntities, autodeskPendingUpdates]);

  return (
    <Tree
      ref={treeAPI}
      disableEdit
      disableDrag
      disableDrop
      indent={theme.sp.compact2H}
      data={tree}
      searchTerm={searchText}
      // searchMatch={node => searchRegex.test(String(node.data.metadata?.type) + node.data.name)}
      width={width}
      height={height}
      openByDefault={false}
      rowHeight={nodeHeight}
      className="objectTreeContainer"
    >
      {CustomNode}
    </Tree>
  );
}

const MemoAutodeskTree = memo(AutodeskTree);

export default function CloudPickerModal() {
  const dispatch = useAppDispatch();

  const onFileLoad = useFileLoad();

  const selectedModal = useAppSelector(selectSelectedModal);
  const isOpen = MODALS.CLOUDPICKER === selectedModal;

  const fileName = useAppSelector(selectFileName);
  const models = useAppSelector(selectSavedModels);
  const totalSize = useAppSelector(selectCloudTotalSize);
  const storageQuota = useAppSelector(selectCloudStorageQuota);
  const isWaitingForUpload = useAppSelector(selectIsWaitingForUpload);
  const isWaitingForModelIdsDeletion = useAppSelector(selectIsWaitingForModelIdsDeletion);

  const isLoggedIn = useAppSelector(selectAccountIsLoggedIn);
  const areChangesCloudSynced = useAppSelector(selectAreChangesCloudSynced);
  const isEmailConfirmed = useAppSelector(selectIsEmailConfirmed);
  const modelCloudId = useAppSelector(selectModelCloudId);
  const isWaitingServerResponse = useAppSelector(selectIsWaitingServerResponse);

  const autodeskEntities = useAppSelector(selectAutodeskEntities);
  const autodeskPendingUpdates = useAppSelector(selectAutodeskPendingUpdates);

  const shouldShowAutodeskSignIn =
    Object.keys(autodeskEntities).length + Object.keys(autodeskPendingUpdates).length == 0;

  const [currentTab, setTab] = useState("sortdesk");

  const switchToTab = (name: string) => {
    setTab(name);
    if (name == "autodesk") {
      dispatch(
        autodeskPopulateChildren({
          entityHandle: {},
        })
      );
    } else if (name == "sortdesk") {
      dispatch(setIsWaitingServerResponseAccount());
      dispatch(listModels());
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      sx={{ p: 2 }}
      onClose={() => dispatch(dismissModal())}
      open={isOpen}
    >
      <DialogTitle>
        <b>Cloud Library</b>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => dispatch(dismissModal())}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ maxHeight: 900, textAlign: "justify", paddingTop: "0" }}>
        <Tabs
          value={currentTab || false}
          onChange={(e, newValue) => switchToTab(newValue)}
          aria-label="Cloud Libraries Tabs"
          sx={{ marginBottom: theme.sppx.spaciousV }}
        >
          <Tab value="sortdesk" label="Sortdesk" />
          <Tab value="autodesk" label="Autodesk Construction Cloud" />
        </Tabs>
        {!isEmailConfirmed && (
          <Alert severity="error">
            Can&apos;t access library, email has not been confirmed.{" "}
            <Link
              href="#"
              onClick={() => {
                dispatch(confirmEmail());
                dispatch(dismissModal());
              }}
            >
              Click here to resend
            </Link>{" "}
            a verification email.
          </Alert>
        )}
        {currentTab == "sortdesk" && (
          <Box>
            <Typography
              sx={{ pt: theme.sppx.compactH, pb: theme.sppx.compactH, pl: theme.sppx.spaciousH }}
            >
              <InlineIcon icon={LaptopChromebookIcon} sx={{ pr: "0.5em" }} />
              <Heavy>Current file</Heavy>
            </Typography>

            <ListItem
              sx={{
                display: "flex",
                justifyContent: fileName ? "flex-start" : "center",
                minHeight: "60px",
                height: "max-content",
                maxHeight: "5rem",
                overflow: "hidden",
                padding: `${theme.sppx.compact2V} 0 ${theme.sppx.compact2V} ${theme.sppx.spaciousH}`,
              }}
              onClick={e => e.stopPropagation()}
            >
              {fileName &&
                ((modelCloudId && areChangesCloudSynced && (
                  <>
                    <CloudDoneIcon
                      sx={{ color: theme.palette.cloudDoneGreen, mr: theme.sppx.spaciousV }}
                    />
                  </>
                )) ||
                  (modelCloudId && !areChangesCloudSynced && (
                    <>
                      <CloudIcon
                        sx={{ color: theme.palette.cloudSyncingYellow, mr: theme.sppx.spaciousV }}
                      />
                    </>
                  )) ||
                  (!modelCloudId && (
                    <>
                      <CloudOffIcon
                        sx={{ color: theme.palette.cloudDesyncedRed, mr: theme.sppx.spaciousV }}
                      />
                    </>
                  )))}
              {fileName && (
                <Typography
                  sx={{
                    pl: theme.sppx.compact2H,
                    textWrap: "wrap",
                    flexShrink: "2",
                    overflowX: "hidden",
                    overflowY: "auto",
                    overflowWrap: "anywhere",
                    mr: theme.sppx.spaciousH,
                    maxHeight: "5rem",
                  }}
                >
                  {fileName}
                </Typography>
              )}
              {fileName && !modelCloudId && <Box flexGrow={1} />}
              {fileName && !modelCloudId && (
                // <Tooltip title="Upload to cloud">
                <Button
                  disabled={isWaitingForUpload}
                  variant="contained"
                  size="large"
                  endIcon={
                    isWaitingForUpload ? (
                      <CircularProgress size="1em" />
                    ) : (
                      <CloudUploadIcon
                        sx={{
                          color: theme.palette.cloudDoneGreen,
                          width: "1.2em",
                          height: "1.2em",
                        }}
                      />
                    )
                  }
                  // size="large"
                  color="secondary"
                  onClick={() => dispatch(uploadModel())}
                  sx={{
                    minWidth: "max-content",
                    height: "32px",
                    padding: "5px 10px 5px 10px",
                    textTransform: "none",
                    background: "none",
                    marginRight: theme.sppx.gapTiny,
                    border: `1px solid ${theme.palette.subtlerGreyBorder}`,
                  }}
                >
                  Upload to cloud
                </Button>
                // </Tooltip>
              )}
              {!fileName && (
                <Typography sx={{ flex: "1", pl: theme.sppx.spaciousV }}>No file opened</Typography>
              )}
            </ListItem>

            <br />
            <Divider />

            <Typography
              sx={{
                color: "black",
                p: "10px 10px 10px 30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Heavy>Cloud</Heavy>{" "}
              <Typography component="span" sx={{ color: theme.palette.greyText, pr: "20px" }}>
                {Math.round(totalSize / (1024 * 1024))}/{storageQuota}MB used
              </Typography>
            </Typography>
            {models.map(x => (
              <BoxRow key={x.id}>
                <Tooltip arrow title="Load locally" placement="bottom">
                  <ToolsListItem
                    onClick={() => {
                      dispatch(loadIfcFile({ modelCloudId: x.id }));
                    }}
                    selected={x.id == modelCloudId}
                    disabled={
                      isWaitingServerResponse ||
                      isWaitingForUpload ||
                      isWaitingForModelIdsDeletion.includes(x.id)
                    }
                    sx={{ padding: theme.pad.spacious, height: "60px" }}
                  >
                    <CloudDoneIcon
                      sx={{ color: theme.palette.cloudDoneGreen, mr: theme.sppx.spaciousV }}
                    />
                    <ListItemText
                      primary={x.name}
                      sx={{
                        paddingLeft: theme.sppx.compact2H,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        "&:hover": {
                          overflowX: "auto",
                        },
                      }}
                    />
                    <Typography
                      component="span"
                      sx={{
                        color: theme.palette.greyText,
                        paddingLeft: "12px",
                        width: "50px",
                        flexGrow: "0",
                        flexShrink: "0",
                      }}
                    >
                      {Math.round(x.size / (1024 * 1024))} MB
                    </Typography>
                  </ToolsListItem>
                </Tooltip>
                <Tooltip key={x.id} arrow title="Import" placement="right">
                  <BoxRow sx={{ alignItems: "center" }}>
                    <IconButton
                      disabled={
                        isWaitingServerResponse ||
                        isWaitingForUpload ||
                        isWaitingForModelIdsDeletion.includes(x.id)
                      }
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(deleteModel({ targetModelCloudId: x.id }));
                      }}
                    >
                      {isWaitingForModelIdsDeletion.includes(x.id) ? (
                        <CircularProgress size="0.8em" />
                      ) : (
                        <DeleteIcon />
                      )}
                    </IconButton>
                  </BoxRow>
                </Tooltip>
              </BoxRow>
            ))}
            {isLoggedIn && models.length == 0 && (
              <Box
                sx={{
                  p: theme.sppx.compactH,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{ ml: theme.sppx.compactV, mb: theme.sppx.compactH }}
                  component="div"
                >
                  No files in the cloud yet.
                </Typography>
                <Typography
                  sx={{ ml: theme.sppx.compactV, mb: theme.sppx.compactV }}
                  component="div"
                >
                  Press the <InlineIcon icon={CloudUploadIcon} active={true} /> to <b>upload</b> the
                  current file to your <b>cloud</b> account
                </Typography>
              </Box>
            )}
            {isWaitingServerResponse && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  pt: theme.sppx.compact2V,
                }}
              >
                <CircularProgress size="1em" />
                <Typography sx={{ pl: theme.sppx.spaciousH }}>Loading model updates...</Typography>
              </Box>
            )}
          </Box>
        )}
        {currentTab == "autodesk" && (
          <Box>
            {shouldShowAutodeskSignIn && (
              <BoxRow
                sx={{
                  alignItems: "center",
                  gap: "0.5rem",
                  justifyContent: "center",
                  width: "100%",
                  paddingTop: theme.sppx.spaciousV,
                  paddingBottom: theme.sppx.spaciousV,
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    dispatch(autodeskLinkRedirect());
                  }}
                >
                  <Typography variant="button" sx={{ textTransform: "none" }}>
                    Sign in with Autodesk <LinkArrow active={false} />
                  </Typography>
                </Button>
              </BoxRow>
            )}
            <BoxRow
              sx={{
                ...(shouldShowAutodeskSignIn && { display: "none" }),
                alignItems: "center",
                gap: "0.5rem",
                justifyContent: "center",
                width: "100%",
                height: "calc(max(250px, 40vh))",
                paddingTop: theme.sppx.spaciousV,
                paddingBottom: theme.sppx.spacious2H,
              }}
            >
              <FillFlexParent>
                {({ width, height }) => (
                  <MemoAutodeskTree width={width} height={height} searchText={""} />
                )}
              </FillFlexParent>
            </BoxRow>
          </Box>
        )}
        <Divider sx={{ pt: theme.sppx.spaciousH }} />
        {currentTab == "sortdesk" && (
          <Box
            sx={{
              paddingTop: theme.sppx.spaciousV,
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Typography>Open a file from your computer instead?</Typography>
            <Button
              variant="contained"
              startIcon={<LaptopChromebookIcon />}
              size="small"
              color="primary"
              component="label"
            >
              <Typography variant="button">Open local .ifc</Typography>
              <LocalIFCPicker onChange={onFileLoad} />
            </Button>
          </Box>
        )}
        {currentTab == "autodesk" && !shouldShowAutodeskSignIn && (
          <Box
            sx={{
              paddingTop: theme.sppx.spaciousV,
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Typography>Want to use another ACC account?</Typography>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                dispatch(autodeskLinkRedirect());
              }}
            >
              <Typography variant="button">
                Sign in with Autodesk <LinkArrow active={false} />
              </Typography>
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
