import { PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { viewerAPI } from "app/common/ViewerAPI";
import { buildandUploadBIMTilesBinary } from "app/common/ViewerAPI/loadUnloadFile";
import {
  AUTHPROVIDERS,
  AutodeskEntity,
  AutodeskEntityHandle,
  INPUTTYPES,
  SEVERITIES,
  SavedModel,
  Section,
  TOOLS,
} from "app/common/types";
import { RootState } from "app/state/store";
import { refreshAutosave } from "app/utils/autoSaveTrigger";
import { firebase } from "app/utils/firebase";
import {
  objectDeepMerge,
  objectExtractValues,
  objectHas,
  objectMergeInto,
} from "app/utils/objectMeta";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  UserCredential,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  getIdToken,
  getRedirectResult,
  reauthenticateWithCredential,
  reauthenticateWithRedirect,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { deleteObject, getMetadata, listAll, ref, uploadBytes } from "firebase/storage";

import {
  dismissModal,
  IIfcMangerState,
  setAutodeskPendingUpdate,
  setIsWaitingForModelIdDeletion,
  setIsWaitingForModelQuery,
  setIsWaitingForModelSchema,
  setIsWaitingForUpload,
} from "../ifcManagerSlice";
import { initialIDSEditorState } from "./idsEditor";
import { initialModelState } from "./model";

export interface ICloudState {
  loginName: string;
  loginEmail: string;
  loginCode: string;
  loginPassword: string;
  loginPasswordConfirmation: string;
  loginNewPassword: string;
  loginNewPasswordConfirmation: string;
  authProvider: AUTHPROVIDERS;
  authUserID: string; // this is for aiding sentry debugging
  isLoginPassMatch: boolean;
  isNewPasswordStrong: boolean;
  isLoginSignInActive: boolean;
  isLoginSignUpActive: boolean;
  activeAccountField: INPUTTYPES | null;
  isLoggedIn: boolean;
  isPasswordResetCodeOK: boolean;
  isEmailConfirmed: boolean;
  name: string;
  firstName: string;
  lastName: string;
  savedModels: SavedModel[];
  modelCloudId: string | null;
  queryModelCloudId: string | null;
  areChangesCloudSynced: boolean;
  isSilentAutosaveFail: boolean;
  isServerNotReachable: boolean;
  isWaitingServerResponse: boolean;
  isWaitingForUpload: boolean;
  // small number of items + https://github.com/reduxjs/redux/discussions/4319 => it's array not set
  isWaitingForModelIdsDeletion: string[];
  queryPollSchemaTimeout: NodeJS.Timeout | null;
  queryPollRetriesRemaining: number;
  storageQuota: number;
  lastSignUpEventSource: string;
  wantsViewerNews: boolean;
  autodeskEntities: Record<string, AutodeskEntity>;
  autodeskPendingUpdates: Record<string, string>; // id -> "loading"
}

const initialLoginDetails = {
  loginName: "",
  loginEmail: "",
  loginCode: "", // used for password reset
  isPasswordResetCodeOK: false,
  loginPassword: "",
  loginPasswordConfirmation: "",
  loginNewPassword: "",
  loginNewPasswordConfirmation: "",
  isLoginPassMatch: false,
  isNewPasswordStrong: false,
  isLoginSignInActive: false,
  isLoginSignUpActive: false,
  activeAccountField: null,
};

export const initialCloudState: ICloudState = {
  ...initialLoginDetails,
  authProvider: AUTHPROVIDERS.EMAIL,
  isLoggedIn: false,
  isEmailConfirmed: false,
  authUserID: "",
  name: "",
  firstName: "",
  lastName: "",
  savedModels: [],
  modelCloudId: null,
  queryModelCloudId: null,
  areChangesCloudSynced: false,
  isSilentAutosaveFail: false,
  isServerNotReachable: false,
  isWaitingServerResponse: false,
  isWaitingForUpload: false,
  isWaitingForModelIdsDeletion: [],
  queryPollSchemaTimeout: null,
  queryPollRetriesRemaining: 0,
  storageQuota: 0,
  lastSignUpEventSource: "",
  wantsViewerNews: false,
  autodeskEntities: {},
  autodeskPendingUpdates: {},
};

const actionCodeSettings = {
  url: import.meta.env.REACT_APP_ORIGIN,
};

export const cloudErrors: { [key: string]: Partial<IIfcMangerState> } = {
  /* snackbar */
  noModelLoaded: { snackbarSeverity: SEVERITIES.ERROR, snackbarMessage: "No model loaded" },
  noUser: { snackbarSeverity: SEVERITIES.ERROR, snackbarMessage: "You are not logged in" },
  noServer: { snackbarSeverity: SEVERITIES.ERROR, snackbarMessage: "Could not reach the server" },
  noStorageQuota: {
    snackbarSeverity: SEVERITIES.ERROR,
    snackbarMessage: "Not enough storage quota",
  },
  fileTooLarge: { snackbarSeverity: SEVERITIES.ERROR, snackbarMessage: "File is too large" },
  noWriting: { snackbarSeverity: SEVERITIES.ERROR, snackbarMessage: "The change was not allowed" },
  uploadFailed: { snackbarSeverity: SEVERITIES.ERROR, snackbarMessage: "Upload failed" },
  sendVerificationFail: {
    snackbarSeverity: SEVERITIES.ERROR,
    snackbarMessage: "Failed to send verification email",
  },
  sendVerificationOk: {
    snackbarSeverity: SEVERITIES.SUCCESS,
    snackbarMessage: "A verification email has been sent",
  },
  updateAccountFail: {
    snackbarSeverity: SEVERITIES.ERROR,
    snackbarMessage: "Failed to update account",
  },
  updateAccountOk: {
    snackbarSeverity: SEVERITIES.SUCCESS,
    snackbarMessage: "Account updated sucessfully",
  },
  updateAccountSameValue: {
    snackbarSeverity: SEVERITIES.SUCCESS,
    snackbarMessage: "Account ok, nothing to update.",
  },
  unauthorizedRequest: {
    snackbarSeverity: SEVERITIES.ERROR,
    snackbarMessage: "Unauthorized. Check email confirmation or sign in again",
  },
  noEditPermission: {
    snackbarSeverity: SEVERITIES.ERROR,
    snackbarMessage: "No edit permission.",
  },
  loginOk: {
    snackbarSeverity: SEVERITIES.SUCCESS,
    snackbarMessage: "Signed in successfully!",
  },
  signOutOk: {
    snackbarSeverity: SEVERITIES.SUCCESS,
    snackbarMessage: "Signed out successfully!",
  },
  signOutFail: {
    snackbarSeverity: SEVERITIES.SUCCESS,
    snackbarMessage: "Couldn't sign out!",
  },
  accountDeletedOk: {
    snackbarSeverity: SEVERITIES.SUCCESS,
    snackbarMessage: "Account has been successfully deleted!",
  },

  /* login alert */
  invalidEmail: { loginSeverity: SEVERITIES.ERROR, loginMessage: "Invalid email" },
  wrongCreds: { loginSeverity: SEVERITIES.ERROR, loginMessage: "Wrong username or password" },
  loginFailed: { loginSeverity: SEVERITIES.ERROR, loginMessage: "Sign in failed" },
  emailExists: { loginSeverity: SEVERITIES.ERROR, loginMessage: "Email already exists" },
  weakPassword: { loginSeverity: SEVERITIES.ERROR, loginMessage: "Password is too weak" },
  registerFailed: { loginSeverity: SEVERITIES.ERROR, loginMessage: "Registration failed" },
  noProfileInit: { loginSeverity: SEVERITIES.ERROR, loginMessage: "Profile failed to initialize" },
  noInternetLogin: {
    loginSeverity: SEVERITIES.ERROR,
    loginMessage: "No internet or connection blocked",
  },
  noSync: {
    loginSeverity: SEVERITIES.ERROR,
    loginMessage: "Failed to sync state",
  },
  tooManyRequests: {
    loginSeverity: SEVERITIES.ERROR,
    loginMessage: "Too many requests, try again later",
  },
  noLocalStorage: {
    loginSeverity: SEVERITIES.ERROR,
    loginMessage: "Browser does not allow local storage",
  },
  resetPasswordOk: {
    loginSeverity: SEVERITIES.SUCCESS,
    loginMessage: "If the email exists, an email has been sent",
  },
  resetPasswordFail: {
    loginSeverity: SEVERITIES.ERROR,
    loginMessage: "Reset email failed to send",
  },
  missingEmail: {
    loginSeverity: SEVERITIES.ERROR,
    loginMessage: "No email provided",
  },
};

export const updateLoginDetailsReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<Partial<typeof initialLoginDetails>>
) => {
  for (const key in payload) {
    // @ts-ignore ts is blind, uncurable
    if (payload[key] !== undefined) {
      // @ts-ignore
      state[key] = payload[key];
    }
  }
  const passw = state.loginNewPassword;
  state.isNewPasswordStrong =
    passw.length >= 8 &&
    passw.length <= 20 &&
    // at least three of the char classes
    +/[0-9]/.test(passw) +
      +/[a-z]/.test(passw) +
      +/[A-Z]/.test(passw) +
      +/[@#$^&*+=]/.test(passw) >=
      3;
  state.isLoginSignUpActive =
    state.isNewPasswordStrong &&
    state.loginNewPassword === state.loginNewPasswordConfirmation &&
    state.loginName.length >= 3 &&
    state.loginEmail.length >= 3;
  state.isLoginSignInActive = state.loginPassword.length >= 8 && state.loginEmail.length > 1;
  state.isLoginPassMatch = state.loginNewPassword === state.loginNewPasswordConfirmation;
};

export const registerWithEmail = createAsyncThunk(
  "cloud/registerWithEmail",
  async (payload, { getState, dispatch }) => {
    const state = (getState() as RootState).ifcManager;

    let userCredential: UserCredential | null = null;
    try {
      userCredential = await createUserWithEmailAndPassword(
        firebase.auth,
        state.loginEmail,
        state.loginNewPassword
      );
    } catch (ex: any) {
      console.error(ex);
      if (ex?.code == "auth/email-already-in-use") return cloudErrors.emailExists;
      if (ex?.code == "auth/invalid-email") return cloudErrors.invalidEmail;
      if (ex?.code == "auth/weak-password") return cloudErrors.weakPassword;
      if (ex?.code == "auth/network-request-failed") return cloudErrors.noInternetLogin;
      Sentry.captureException(ex, scope => scope.setLevel("log"));
      if (ex?.code == "auth/web-storage-unsupported") return cloudErrors.noLocalStorage;
      if (ex?.code == "auth/too-many-requests") return cloudErrors.tooManyRequests;
      return cloudErrors.registerFailed;
    }

    try {
      const user = userCredential.user;
      await firebase.updateProfile(user, { displayName: state.loginName });

      dispatch(confirmEmail());

      dispatch(updateAccount());

      firebase.ga_event("signup", {
        category: "button",
        action: "signup",
        label: state.lastSignUpEventSource,
      });

      console.log("registerWithEmail: wantsViewerNews:", state.wantsViewerNews);
      if (state.wantsViewerNews) {
        firebase.subscribeToNewsletter({ email: state.loginEmail, feature: "wantsViewerNews" });
      }

      return {
        isLoggedIn: true,
        ...initialLoginDetails,
        name: user.displayName,
        loginName: user.displayName,
        loginEmail: user.email,
        selectedModal: null,
        loginMessage: null,
      };
    } catch (ex: any) {
      console.error(ex);
      if (ex?.code == "auth/network-request-failed") return cloudErrors.noProfileInit;
      Sentry.captureException(ex, scope => scope.setLevel("log"));
      return cloudErrors.noProfileInit;
    }
  }
);

export const loginWithEmail = createAsyncThunk(
  "cloud/loginWithEmail",
  async (payload, { getState }) => {
    const state = (getState() as RootState).ifcManager;

    let userCredential: UserCredential | null = null;
    try {
      userCredential = await signInWithEmailAndPassword(
        firebase.auth,
        state.loginEmail,
        state.loginPassword
      );
    } catch (ex: any) {
      console.error(ex);
      if (ex?.code == "auth/invalid-credential") return cloudErrors.wrongCreds;
      if (ex?.code == "auth/invalid-login-credential") return cloudErrors.wrongCreds;
      if (ex?.code == "auth/user-not-found") return cloudErrors.wrongCreds;
      if (ex?.code == "auth/invalid-email") return cloudErrors.invalidEmail;
      if (ex?.code == "auth/network-request-failed") return cloudErrors.noInternetLogin;
      Sentry.captureException(ex, scope => scope.setLevel("log"));
      if (ex?.code == "auth/too-many-requests") return cloudErrors.tooManyRequests;
      if (ex?.code == "auth/web-storage-unsupported") return cloudErrors.noLocalStorage;
      return cloudErrors.loginFailed;
    }

    const user = userCredential.user;
    try {
      const idTokenResult = await user.getIdTokenResult();
      if (idTokenResult.claims.tier == "pro") {
        // todo: add tiers logic
        // console.log(payload.email + " is a pro user");
      } else {
        // console.log(payload.email + " is a free user");
      }

      // there's no checkbox under sign in with email
      // if (state.wantsViewerNews) {
      //   firebase.subscribeToNewsletter({ email: state.loginEmail });
      // }

      return {
        isLoggedIn: true,
        ...initialLoginDetails,
        name: user.displayName,
        loginName: user.displayName,
        loginEmail: user.email,
        selectedModal: null,
        loginMessage: null,
      };
    } catch (ex) {
      Sentry.captureException(ex, scope => scope.setLevel("log"));
      console.error("failed token:", ex);
    }

    return {
      isLoggedIn: false,
    };
  }
);

export const confirmEmail = createAsyncThunk("cloud/confirmEmail", async () => {
  const user = firebase.auth.currentUser;
  if (!user) {
    Sentry.captureMessage("Invalid user");
    console.error("Invalid user");
    return cloudErrors.noServer;
  }

  try {
    await sendEmailVerification(user, actionCodeSettings);
    return cloudErrors.sendVerificationOk;
  } catch (ex: any) {
    console.error(ex);
    if (ex?.code == "auth/invalid-email") return cloudErrors.invalidEmail;
    if (ex?.code == "auth/network-request-failed") return cloudErrors.noInternetLogin;
    if (ex?.code == "auth/user-not-found") return cloudErrors.resetPasswordOk;
    Sentry.captureException(ex, scope => scope.setLevel("log"));
    if (ex?.code == "auth/too-many-requests") return cloudErrors.tooManyRequests;
    return cloudErrors.sendVerificationFail;
  }
});

export const sendResetPasswordEmail = createAsyncThunk(
  "cloud/sendResetPasswordEmail",
  async (payload, { getState }) => {
    const state = (getState() as RootState).ifcManager;

    try {
      const actionCodeSettings = {
        url: import.meta.env.REACT_APP_ORIGIN,
      };

      await sendPasswordResetEmail(firebase.auth, state.loginEmail, actionCodeSettings);
      return cloudErrors.resetPasswordOk;
    } catch (ex: any) {
      console.error(ex);
      if (ex?.code == "auth/invalid-email") return cloudErrors.invalidEmail;
      if (ex?.code == "auth/missing-email") return cloudErrors.missingEmail;
      if (ex?.code == "auth/network-request-failed") return cloudErrors.noInternetLogin;
      if (ex?.code == "auth/user-not-found") return cloudErrors.resetPasswordOk;
      Sentry.captureException(ex, scope => scope.setLevel("log"));
      if (ex?.code == "auth/too-many-requests") return cloudErrors.tooManyRequests;
      return cloudErrors.resetPasswordFail;
    }
  }
);
export const updatePassword = createAsyncThunk(
  "cloud/resetPassword",
  async (payload, { getState }) => {
    const state = (getState() as RootState).ifcManager;
    try {
      await confirmPasswordReset(firebase.auth, state.loginCode, state.loginPassword);
      return cloudErrors.resetPasswordOk;
    } catch (ex: any) {
      console.error(ex);
      if (ex?.code == "auth/invalid-email") return cloudErrors.invalidEmail;
      if (ex?.code == "auth/missing-email") return cloudErrors.missingEmail;
      if (ex?.code == "auth/network-request-failed") return cloudErrors.noInternetLogin;
      if (ex?.code == "auth/user-not-found") return cloudErrors.resetPasswordOk;
      Sentry.captureException(ex, scope => scope.setLevel("log"));
      if (ex?.code == "auth/too-many-requests") return cloudErrors.tooManyRequests;
      return cloudErrors.resetPasswordFail;
    }
  }
);

export const loginWithMicrosoft = createAsyncThunk(
  "cloud/loginWithMicrosoft",
  async (payload, { getState }) => {
    try {
      const state = (getState() as RootState).ifcManager;

      const provider = new OAuthProvider("microsoft.com");
      await signInWithRedirect(firebase.auth, provider);
      const user = firebase.auth.currentUser;

      const result = await getRedirectResult(firebase.auth);
      if (result) {
        const additionalUserInfo = getAdditionalUserInfo(result);
        if (additionalUserInfo?.isNewUser) {
          firebase.ga_event("signup", {
            category: "button",
            action: "signup",
            label: state.lastSignUpEventSource,
          });
        }
      }

      console.log("loginWithMicrosoft: wantsViewerNews:", state.wantsViewerNews);
      if (state.wantsViewerNews) {
        firebase.subscribeToNewsletter({ email: state.loginEmail, feature: "wantsViewerNews" });
      }

      return {
        isLoggedIn: true,
        ...initialLoginDetails,
        name: user?.displayName,
        loginName: user?.displayName,
        loginEmail: user?.email,
        selectedModal: null,
        loginMessage: null,
      };
    } catch (ex) {
      Sentry.captureException(ex, scope => scope.setLevel("log"));
      console.error(ex);
      return cloudErrors.loginFailed;
    }
  }
);

export const loginWithGoogle = createAsyncThunk(
  "cloud/loginWithGoogle",
  async (payload, { getState }) => {
    try {
      const state = (getState() as RootState).ifcManager;

      const provider = new GoogleAuthProvider();
      await signInWithRedirect(firebase.auth, provider);
      const user = firebase.auth.currentUser;

      const result = await getRedirectResult(firebase.auth);
      if (result) {
        const additionalUserInfo = getAdditionalUserInfo(result);
        if (additionalUserInfo?.isNewUser) {
          firebase.ga_event("signup", {
            category: "button",
            action: "signup",
            label: state.lastSignUpEventSource,
          });
        }
      }

      console.log("loginWithGoogle: wantsViewerNews:", state.wantsViewerNews);
      if (state.wantsViewerNews) {
        firebase.subscribeToNewsletter({ email: state.loginEmail, feature: "wantsViewerNews" });
      }

      return {
        isLoggedIn: true,
        ...initialLoginDetails,
        name: user?.displayName,
        loginName: user?.displayName,
        loginEmail: user?.email,
        selectedModal: null,
        loginMessage: null,
      };
    } catch (ex) {
      Sentry.captureException(ex, scope => scope.setLevel("log"));
      console.error(ex);
      return cloudErrors.loginFailed;
    }
  }
);

const relogin = createAsyncThunk("cloud/relogin", async (payload, { getState }) => {
  const state = (getState() as RootState).ifcManager;
  const user = firebase.auth.currentUser;

  if (!user || !user.email) {
    Sentry.captureMessage("Invalid user");
    console.error("Invalid user");
    return cloudErrors.noServer;
  }

  try {
    if (user.providerData[0].providerId == "google.com") {
      await reauthenticateWithRedirect(user, new GoogleAuthProvider());
    } else if (user.providerData[0].providerId == "microsoft.com") {
      await reauthenticateWithRedirect(user, new OAuthProvider("microsoft.com"));
    } else {
      await reauthenticateWithCredential(
        user,
        EmailAuthProvider.credential(user.email, state.loginPassword)
      );
      return {
        loginSeverity: SEVERITIES.SUCCESS,
        loginMessage: null,
        loginPassword: "",
        ...cloudErrors.loginOk,
      };
    }
  } catch (ex: any) {
    console.error(ex);
    if (ex?.code == "auth/invalid-credential") return cloudErrors.wrongCreds;
    if (ex?.code == "auth/invalid-login-credential") return cloudErrors.wrongCreds;
    if (ex?.code == "auth/network-request-failed") return cloudErrors.noInternetLogin;
    Sentry.captureException(ex, scope => scope.setLevel("log"));
    if (ex?.code == "auth/too-many-requests") return cloudErrors.tooManyRequests;
    return cloudErrors.loginFailed;
  }
});

const saveAccountChanges = createAsyncThunk(
  "cloud/saveAccountChanges",
  async (payload: { name?: boolean; email?: boolean; password?: boolean }, { getState }) => {
    const user = firebase.auth.currentUser;

    if (!user || !user.email) {
      Sentry.captureMessage("Invalid user");
      console.error("Invalid user");
      return cloudErrors.noServer;
    }

    const state = (getState() as RootState).ifcManager;

    if (payload.name && state.loginName != user.displayName) {
      try {
        await firebase.updateProfile(user, { displayName: state.loginName });
      } catch (ex: any) {
        console.error(ex);
        if (ex?.code == "auth/network-request-failed") return cloudErrors.updateAccountFail;
        Sentry.captureException(ex, scope => scope.setLevel("log"));
        return cloudErrors.updateAccountFail;
      }
      return {
        ...cloudErrors.updateAccountOk,
        activeAccountField: true,
        ..._updateAccountReducer(),
      };
    }

    if (payload.email && state.loginEmail != user.email) {
      try {
        // v1: this will leave the current email as is until user clicks on the email link
        // which will auto update the email and set it as confirmed, no other handler required
        await verifyBeforeUpdateEmail(user, state.loginEmail, actionCodeSettings);

        // v2: this will update the email immidiately, but leave it unconfirmed
        // another sendEmailVerification is required
        // await firebase.updateEmail(user, state.loginEmail);
      } catch (ex: any) {
        Sentry.captureException(ex, scope => scope.setLevel("log"));
        console.error(ex);
        return cloudErrors.sendVerificationFail;
      }
      return {
        ...cloudErrors.sendVerificationOk,
        activeAccountField: true,
        ..._updateAccountReducer(),
      };
    }

    if (payload.password && state.loginNewPassword) {
      try {
        await firebase.updatePassword(user, state.loginNewPassword);
      } catch (ex: any) {
        Sentry.captureException(ex, scope => scope.setLevel("log"));
        console.error(ex);
        return cloudErrors.updateAccountFail;
      }
      return {
        ...cloudErrors.updateAccountOk,
        activeAccountField: true,
        ..._updateAccountReducer(),
        loginNewPassword: "",
        loginNewPasswordConfirmation: "",
      };
    }

    return cloudErrors.updateAccountSameValue;
  }
);
const setActiveAccountField = createAsyncThunk(
  "cloud/setActiveAccountField",
  async (payload: INPUTTYPES | null) => {
    const user = firebase.auth.currentUser;

    if (!user || !user.email) {
      Sentry.captureMessage("Invalid user");
      console.error("Invalid user");
      return cloudErrors.noServer;
    }

    const result: Partial<IIfcMangerState> = {};
    result.activeAccountField = payload;

    if (payload != INPUTTYPES.NAME && user.displayName) {
      result.loginName = user.displayName;
    }

    if (payload != INPUTTYPES.EMAIL && user.email) {
      result.loginEmail = user.email;
    }

    if (payload != INPUTTYPES.PASSWORD) {
      result.loginNewPassword = "";
      result.loginNewPasswordConfirmation = "";
    }

    return result;
  }
);

const _updateAccountReducer = () => {
  const result: Partial<IIfcMangerState> = {};

  const user = firebase.auth.currentUser;
  console.log("_updateAccountReducer: user:", user);
  if (user) {
    /* Problem: The onAuthStateChanged event takes 500ms to trigger
          during that time the splash page will show for the logged in users
          then flash to the autoloaded signed in viewer
     Fix: Firestore uses an indexedDB as its storage, 
          use it an unchecked cache until the message */
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("loginName", user.displayName ?? "No Name");
    result.isLoggedIn = true;
    result.authUserID = user.uid;
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    result.name = user.displayName ?? "No Name";
    // note: user.providerId is "firebase" when user..data..providerId is "microsoft.com"
    const authProvider = user.providerData.find(
      x => x.providerId == "microsoft.com" || x.providerId == "google.com"
    );
    result.isEmailConfirmed = user.emailVerified || authProvider !== undefined;

    if (
      authProvider?.providerId &&
      (Object.values(AUTHPROVIDERS) as string[]).includes(authProvider.providerId)
    ) {
      result.authProvider = authProvider.providerId as AUTHPROVIDERS;
    } else {
      result.authProvider = AUTHPROVIDERS.EMAIL;
    }
    if (user.email) {
      result.loginEmail = user.email;
    }
    if (user.displayName) {
      result.loginName = user.displayName;
    }
  } else {
    localStorage.setItem("isLoggedIn", "false");
    localStorage.setItem("loginName", "");
    result.isLoggedIn = false;
  }

  console.log("Update account returned:", result);
  return result;
};

export const updateAccount = createAsyncThunk(
  "ifcManager/updateAccount",
  async (_payload, { dispatch }): Promise<Partial<IIfcMangerState>> => {
    console.log("updateAccount:");
    const stateUpdate = _updateAccountReducer();

    const user = firebase.auth.currentUser;
    if (!user || !user.email) {
      console.error("updateAccount: No user or email");
      return stateUpdate;
    }

    // This is the first place where the user becomes available after a page load
    // handle the autodesk OAuth url code
    {
      const code = new URLSearchParams(window.location.search).get("code");
      if (code) {
        dispatch(autodeskLinkAccount());
      }
    }

    console.log("updateAccount: user data");
    try {
      console.log("updateAccount: user data: user.uid:", user.uid);
      const userDataRef = doc(firebase.db, "/users", user.uid);

      const userDataDoc = await getDoc(userDataRef);
      const userData = userDataDoc.data();
      console.log("updateAccount: user data:", userData);

      stateUpdate.storageQuota = userData?.["storageQuota"] ?? 100;

      console.log("updateAccount: user data done: storageQuota:", stateUpdate.storageQuota);
    } catch (ex) {
      console.error("updateAccount: user data failed:", ex);
    }

    return stateUpdate;
  }
);

const deleteAccount = createAsyncThunk("cloud/deleteAccount", async () => {
  const user = firebase.auth.currentUser;

  if (!user) {
    Sentry.captureMessage("Invalid user");
    console.error("Invalid user");
    return cloudErrors.noServer;
  }

  await firebase.deleteAccount();

  return {
    ...initialCloudState,
    ...cloudErrors.accountDeletedOk,
    selectedModal: null,
  };
});

const setIsWaitingServerResponseAccountReducer = (state: IIfcMangerState) => {
  state.isWaitingServerResponse = true;
};

const setIsWaitingForUploadReducer = (state: IIfcMangerState) => {
  state.isWaitingForUpload = true;
};

const setIsWaitingForModelIdDeletionReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<{ modelCloudId: string }>
) => {
  state.isWaitingForModelIdsDeletion.push(payload.modelCloudId);
};

const accountSignOut = createAsyncThunk("cloud/signOut", async () => {
  try {
    await signOut(firebase.auth);
  } catch (ex: any) {
    console.error(ex);

    if (ex?.code == "auth/network-request-failed") return cloudErrors.noInternetLogin;
    Sentry.captureException(ex, scope => scope.setLevel("log"));
    if (ex?.code == "auth/too-many-requests") return cloudErrors.tooManyRequests;

    return cloudErrors.signOutFail;
  }

  return {
    ...initialCloudState,
    ...initialIDSEditorState,
    ...cloudErrors.signOutOk,
  };
});

const initAccountsReducer = () => {
  /* no update account in favour of cached localStorage */
  // updateAccountReducer(state);
};

const _listModels = async () => {
  const user = firebase.auth.currentUser;
  if (!firebase.storage || !user) {
    Sentry.captureMessage("Invalid user");
    console.error("Could not list models, user:", user);
    return {
      ...cloudErrors.noServer,
      isWaitingServerResponse: false,
    };
  }

  try {
    const storageRef = ref(firebase.storage, `/spaces/${user.uid}`);
    const filesRef = await listAll(storageRef);
    // note .updated is also available to order chronologically
    const savedModelMeta = await Promise.all(filesRef.items.map(x => getMetadata(x)));

    const querySnapshot = await getDocs(collection(firebase.db, `spaces/${user.uid}/files`));

    const savedModels = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      size: savedModelMeta.filter(x => x.name == doc.id).at(0)?.size ?? 0,
    })) as SavedModel[];

    return {
      savedModels,
      isServerNotReachable: false,
      isWaitingServerResponse: false,
      // note: this would be handled by an account check timer,
      // but here is more responsive, works because ok backend rules => ok email
      isEmailConfirmed: true,
    };
  } catch (ex: any) {
    console.error("Could not list models:", ex);

    // this translates to no network connection
    if (ex?.code == "storage/retry-limit-exceeded")
      return {
        ...cloudErrors.noServer,
        isServerNotReachable: true,
        isWaitingServerResponse: false,
      };

    if (ex?.code == "storage/unauthorized")
      return {
        ...cloudErrors.unauthorizedRequest,
        isWaitingServerResponse: false,
      };

    Sentry.captureException(ex, scope => scope.setLevel("log"));
    // trigger connection checking cycle
    refreshAutosave();
    return {
      ...cloudErrors.noServer,
      isServerNotReachable: true,
      isWaitingServerResponse: false,
    };
  }
};

export const listModels = createAsyncThunk<Partial<IIfcMangerState>>(
  "cloud/listModels",
  async (_payload, { dispatch }) => {
    const result = await _listModels();
    // note: this is the currently single funnel listModels through which cloud interactions begin
    // this retry logic might need to me abstracted and reused
    if (objectHas(result, cloudErrors.unauthorizedRequest)) {
      const user = firebase.auth.currentUser;
      if (!user) {
        Sentry.captureMessage("Invalid user");
        console.error("Invalid user");
        return {
          ...cloudErrors.noServer,
          isWaitingServerResponse: false,
        };
      }
      await new Promise(r => setTimeout(r, 2000));

      console.log("force token refresh");
      await getIdToken(user, true);
      console.log("token refresh successful");

      dispatch(updateAccount());
      return await _listModels();
    }
    return result;
  }
);

const _saveCloudState = async (state: RootState, modelCloudId?: string) => {
  const ifcManager = state.ifcManager;
  if (!ifcManager.isLoggedIn) return;
  try {
    const viewer = viewerAPI();
    const partialState = JSON.stringify({
      customSections: ifcManager.customSections.map(
        section =>
          ({
            ...section,
            origin: viewer.getSectionOrigin(section.id),
            normal: viewer.getSectionNormal(section.id),
          }) as Section
      ),
      nextSectionNumber: ifcManager.nextSectionNumber,
      plans: ifcManager.plans,
      dimensions: ifcManager.dimensions,
      selectedPlan: ifcManager.selectedPlan,
      modelID: ifcManager.modelID,
      propertiesModelUUID: ifcManager.propertiesModelUUID,
      propertiesExpressID: ifcManager.propertiesExpressID,
      lastSelectedPropertyName: ifcManager.lastSelectedPropertyName,
      selectedTools: ifcManager.selectedTools,
      activeTools: ifcManager.activeTools,
      isLeftPanelOpen: ifcManager.isLeftPanelOpen,
      isRightPanelOpen: ifcManager.isRightPanelOpen,
      isOrthoEnabled: ifcManager.isOrthoEnabled,
      isNavGizmoEnabled: ifcManager.isNavGizmoEnabled,
    });

    const user = firebase.auth.currentUser;
    if (!firebase.db || !user || (!ifcManager.modelCloudId && !modelCloudId)) {
      // this is an automatic background action, user needs no feeback
      return {};
    }

    const projectRef = doc(
      firebase.db,
      `/spaces/${user.uid}/projects`,
      `${modelCloudId ?? ifcManager.modelCloudId}`
    );
    // note: this is without {merge: true} to override full db state
    await setDoc(projectRef, { state: partialState });

    // todo: collaboration & live updates
    // const unsubscribe = onSnapshot(projectRef, (doc) => {
    //   restoreCloudState(doc);
    // });

    return {
      areChangesCloudSynced: true,
      isSilentAutosaveFail: true,
    };
  } catch (ex: any) {
    // trigger infinite chain of silent try to sync
    refreshAutosave();
    let err = null;
    if (!ifcManager.isSilentAutosaveFail) {
      if (ex?.code == "auth/network-request-failed") err = cloudErrors.noInternetLogin;
      if (ex?.code == "auth/too-many-requests") err = cloudErrors.tooManyRequests;
      if (ex?.code == "auth/user-not-found") err = cloudErrors.resetPasswordOk;
      Sentry.captureException(ex, scope => scope.setLevel("log"));
      if (err == null) err = cloudErrors.noSync;
    }

    return {
      isSilentAutosaveFail: true,
      ...err,
    };
  }
};

export const saveCloudState = createAsyncThunk(
  "ifcManager/saveCloudState",
  async (payload, { getState }) => {
    const state = getState() as RootState;

    return await _saveCloudState(state);
  }
);

export const checkServerConnection = createAsyncThunk(
  "ifcManager/checkServerConnection",
  async (payload, { getState }) => {
    const state = (getState() as RootState).ifcManager;

    // only start checking after a failed operation
    if (!state.isServerNotReachable) return {};

    const user = firebase?.auth?.currentUser;
    // if the user has meanwhile logged out, don't continue
    if (!firebase.storage || !user) return {};

    const res = await _listModels();
    if (res.isServerNotReachable) {
      refreshAutosave();
      return {};
      //fixme:? also after email has not been confirmed?
    }

    return res;
  }
);

export const restoreCloudState = async (modelCloudId: string) => {
  const user = firebase.auth.currentUser;
  if (!firebase.db || !user) {
    Sentry.captureMessage("Invalid user");
    console.error("Invalid user");
    return cloudErrors.noServer;
  }

  try {
    const projectRef = doc(firebase.db, `/spaces/${user.uid}/projects`, `${modelCloudId}`);
    const projectDoc = await getDoc(projectRef);
    const projectProps = projectDoc.data();

    if (!projectProps) return {};
    const cloudState = JSON.parse(projectProps.state) as IIfcMangerState;

    if (cloudState.selectedTools) cloudState.selectedTools[TOOLS.PROPERTIES] = false;
    if (cloudState.activeTools) cloudState.activeTools[TOOLS.PROPERTIES] = false;

    const viewer = viewerAPI();
    viewer.restoreDimensions(cloudState.dimensions);
    viewer.restoreSections(cloudState.customSections);
    viewer.setIsRightPanelOpen(false);

    // if (
    //   viewer._highlighterModel &&
    //   cloudState?.propertiesModelUUID &&
    //   cloudState?.propertiesExpressID
    // ) {
    //   viewer._highlighterModel = viewer?._fragments?.groups?.values?.()?.next?.()?.value ?? null;
    //   const model = viewer._highlighterModel;
    //   if (model) {
    //     cloudState.modelID = model.id;
    //     cloudState.propertiesModelUUID = model.id;
    //     viewer.highlighterExpressId = cloudState?.propertiesExpressID;
    //     viewer.highlightByID({
    //       modelUUID: String(model.uuid),
    //       expressID: String(cloudState.propertiesExpressID),
    //     });
    //   }
    // }

    if (cloudState?.selectedPlan) {
      viewer.goToPlan(cloudState.selectedPlan);
    }

    return cloudState;
  } catch (ex) {
    // note: docs errors are not captured because they aren't documented
    // todo: add a no network case to have fewer sentry errors
    Sentry.captureException(ex, scope => scope.setLevel("log"));
    return cloudErrors.noServer;
  }
};

export const uploadModel = createAsyncThunk(
  "ifcManager/uploadModel",
  async (_payload, { getState, dispatch }) => {
    const state = getState() as RootState;
    const ifcManager = state.ifcManager;
    const viewer = viewerAPI();

    const user = firebase.auth.currentUser;

    /* frontent prechecks for quick & insighful errors */
    if (!viewer?.modelSrc) return cloudErrors.noModelLoaded;
    if (!user || !state.ifcManager.isLoggedIn) {
      return cloudErrors.noUser;
    }
    if (!firebase.storage) {
      Sentry.captureMessage("Firebase storage is not loaded");
      console.error("Firebase storage is not loaded");
      return cloudErrors.noServer;
    }
    dispatch(setIsWaitingForUpload());

    const savedModels = (await _listModels()).savedModels;
    if (!savedModels)
      return {
        isWaitingForUpload: false,
        ...cloudErrors.noServer,
      };

    const totalSize = savedModels.map(x => x.size).reduce((a, b) => a + b, 0);
    if (viewer.modelSrc.length + totalSize > state.ifcManager.storageQuota * 1024 * 1024) {
      return { isWaitingForUpload: false, ...cloudErrors.noStorageQuota };
    }

    const fileID = crypto.randomUUID();
    const storageRef = ref(firebase.storage, `/spaces/${user.uid}/${fileID}`);
    const dbFileRef = doc(firebase.db, `/spaces/${user.uid}/files`, `${fileID}`);

    /* update storage */
    try {
      await uploadBytes(storageRef, viewer.modelSrc);
    } catch (ex: any) {
      // .name: "FirebaseError"
      if (ex?.status == 413 && ex?.customData?.serverResponse == "Payload Too Large") {
        return { isWaitingForUpload: false, ...cloudErrors.fileTooLarge };
      }
      // note: this covers all cases in storage.rules: !permission|>size|!token
      if (
        ex?.status == 403 &&
        ex?.customData?.serverResponse == "Permission denied. No WRITE permission."
      ) {
        return { isWaitingForUpload: false, ...cloudErrors.noWriting };
      }

      Sentry.captureException(ex, scope => scope.setLevel("log"));
      return { isWaitingForUpload: false, ...cloudErrors.uploadFailed };
    }

    /* update database entry */
    try {
      await setDoc(dbFileRef, { name: ifcManager.fileName }, { merge: true });
    } catch (ex) {
      Sentry.captureException(ex, scope => scope.setLevel("log"));
      return { isWaitingForUpload: false, ...cloudErrors.noServer };
    }

    // fixme: edge case: previous calls work but this one doesn't => partial state
    // pro: firebase has auto retry
    await _saveCloudState(state, fileID);
    const groupKey = viewer._fragments.groups.keys().next().value;
    const model = viewer._fragments.groups.get(groupKey);
    if (model) {
      await buildandUploadBIMTilesBinary({ model, storageID: fileID });
    }

    const newModelDetails = { ...ifcManager.modelDetails };
    if (ifcManager.modelID) {
      newModelDetails[ifcManager.modelID] = {
        ...newModelDetails[ifcManager.modelID],
        modelCloudID: fileID,
      };
    }
    return {
      modelCloudId: fileID,
      modelDetails: newModelDetails,
      modelUploadedTime: new Date().toISOString(),
      // add a dummy non-zero value to avoid flicker to file not processed state before dispatches finish
      queryPollRetriesRemaining: 60,
      areChangesCloudSynced: true,
      selectedModal: null,
      isWaitingForUpload: false,
    };
  }
);

export const deleteModel = createAsyncThunk(
  "ifcManager/deleteModel",
  async (payload: { targetModelCloudId: string }, { getState, dispatch }) => {
    const state = getState() as RootState;

    const user = firebase.auth.currentUser;
    if (!firebase.storage || !user) {
      Sentry.captureMessage("assertion broken: no user or firebase for deleteModel");
      console.error("Invalid user");
      return null;
    }

    dispatch(setIsWaitingForModelIdDeletion({ modelCloudId: payload.targetModelCloudId }));

    const storageRef = ref(firebase.storage, `/spaces/${user.uid}/${payload.targetModelCloudId}`);
    const bimTilesStorageRef = ref(
      firebase.storage,
      `/spaces/${user.uid}/${payload.targetModelCloudId}-bimtiles`
    );
    const bimTilesMetaStorageRef = ref(
      firebase.storage,
      `/spaces/${user.uid}/${payload.targetModelCloudId}-bimtiles_meta.json`
    );

    const dbFileRef = doc(
      firebase.db,
      `/spaces/${user.uid}/files`,
      `${payload.targetModelCloudId}`
    );

    try {
      try {
        await deleteObject(storageRef);
      } catch (ex: any) {
        console.log("ex, ex.code, ex.message:", ex, ex.code, ex.message);
        if (ex?.message?.includes?.("storage/object-not-found") != true) throw ex;
      }
      try {
        await deleteObject(bimTilesStorageRef);
      } catch (ex: any) {
        if (ex?.message?.includes?.("storage/object-not-found") != true) throw ex;
      }
      try {
        await deleteObject(bimTilesMetaStorageRef);
      } catch (ex: any) {
        if (ex?.message?.includes?.("storage/object-not-found") != true) throw ex;
      }
    } catch (ex: any) {
      Sentry.captureException(ex, scope => scope.setLevel("log"));
      console.error("can't delete file:", ex);
      if (ex?.code == "storage/unauthorized")
        return (state: IIfcMangerState) => {
          state.isWaitingForModelIdsDeletion.splice(
            state.isWaitingForModelIdsDeletion.indexOf(payload.targetModelCloudId),
            1
          );
          objectMergeInto(cloudErrors.noEditPermission, state);
        };

      // note: the doc is not deleted to allow a retry later
      return (state: IIfcMangerState) => {
        state.isWaitingForModelIdsDeletion.splice(
          state.isWaitingForModelIdsDeletion.indexOf(payload.targetModelCloudId),
          1
        );
        objectMergeInto(cloudErrors.noServer, state);
      };
    }

    // "Warning: Deleting a document does not delete its subcollections!"
    try {
      await deleteDoc(dbFileRef);
    } catch (ex: any) {
      Sentry.captureException(ex, scope => scope.setLevel("log"));
      console.error("can't delete document:", ex);
      return (state: IIfcMangerState) => {
        state.isWaitingForModelIdsDeletion.splice(
          state.isWaitingForModelIdsDeletion.indexOf(payload.targetModelCloudId),
          1
        );
        objectMergeInto(cloudErrors.noServer, state);
      };
    }

    const result = await _listModels();
    if (
      result.savedModels &&
      !result.savedModels.find(x => x.id == state.ifcManager.modelCloudId)
    ) {
      objectMergeInto(
        {
          modelCloudId: null,
          modelUploadedTime: null,
          areChangesCloudSynced: false,
          // partial reset initialModelState
          isModelReadyToQuery: initialModelState.isModelReadyToQuery,
          modelQueryTypes: initialModelState.modelQueryTypes,
          modelQuerySelectedTypes: initialModelState.modelQuerySelectedTypes,
          modelQuerySchema: initialModelState.modelQuerySchema,
          modelQueryExportedAttributes: initialModelState.modelQueryExportedAttributes,
          isWaitingForModelSchema: initialModelState.isWaitingForModelSchema,
          isWaitingForModelQuery: initialModelState.isWaitingForModelQuery,
          modelQueryResult: initialModelState.modelQueryResult,
          modelQuerySelectGeneration: initialModelState.modelQuerySelectGeneration,
          modelQueryResultGeneration: initialModelState.modelQueryResultGeneration,
        },
        result
      );
    }

    return (state: IIfcMangerState) => {
      state.isWaitingForModelIdsDeletion.splice(
        state.isWaitingForModelIdsDeletion.indexOf(payload.targetModelCloudId),
        1
      );
      objectMergeInto(result, state);
    };
  }
);

const setIsWaitingForModelSchemaReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<boolean>
) => {
  state.isWaitingForModelSchema = payload;
};

export const queryFileSchema = createAsyncThunk(
  "ifcManager/queryFileSchema",
  async (
    payload: undefined | { refreshPolling?: boolean },
    { getState, dispatch }
  ): Promise<Partial<IIfcMangerState>> => {
    const state = (getState() as RootState).ifcManager;

    if (state.isWaitingForModelSchema) return { queryPollSchemaTimeout: null };
    if (!state.queryModelCloudId) return { queryPollSchemaTimeout: null };

    const user = firebase.auth.currentUser;
    if (!firebase.storage || !user) {
      // Sentry.captureMessage("assertion broken: no user or firebase for queryFileSchema");
      console.error("Invalid user");
      return { queryPollSchemaTimeout: null };
    }

    // todo: integrate thunk.pending into current state API
    dispatch(setIsWaitingForModelSchema(true));

    // note: there is a single caller in the UI with refreshPolling: true
    // => only one timeout queue & no unexpected UI updates
    if (payload?.refreshPolling == true) {
      if (state.queryPollSchemaTimeout) {
        clearTimeout(state.queryPollSchemaTimeout);
      }
    }

    try {
      const response = await firebase.queryFileSchema(state.queryModelCloudId);
      const res = response?.data as {
        message: string;
        schema?: any;
      };

      const extractAttributes = (schema: any) => {
        const flatSchema = {};
        for (const index in schema) {
          const attributes: { [key: string]: any } = {};
          const sentinel = schema[index].mergedSentinel;

          objectExtractValues(sentinel, (acc, key) => `${acc}/${key}`)
            .filter(
              x =>
                !x.pathString.startsWith("Representation") &&
                !x.pathString.startsWith("ObjectPlacement") &&
                !x.pathString.startsWith("_id") &&
                x.value !== undefined
            )
            .forEach(x => {
              attributes[x.pathString.replace(/^properties\//, "").replace(/^quantities\//, "")] = {
                queryPath: x.pathStruct,
                value: (x.value ?? "").toString(),
              };
            });
          //@ts-ignore
          flatSchema[schema[index]._id] = attributes;
        }
        return flatSchema;
      };

      console.log("res:", res);

      if (res?.message == "Collection not found") {
        // manually declare it failed if after 5 mins it's not processed
        if (
          !state.modelUploadedTime ||
          (+new Date() - +new Date(state.modelUploadedTime)) / 60000 >= 5
        ) {
          return {
            queryPollSchemaTimeout: null,
            modelQuerySchema: null,
            modelQueryTypes: null,
            isModelReadyToQuery: false,
            isWaitingForModelSchema: false,
            queryPollRetriesRemaining: 0,
          };
        }

        const refreshPolling = payload?.refreshPolling == true;
        const timeoutId =
          refreshPolling || state.queryPollRetriesRemaining >= 0
            ? setTimeout(() => {
                dispatch(queryFileSchema());
              }, 5000)
            : null;

        return {
          queryPollSchemaTimeout: timeoutId,
          modelQuerySchema: null,
          modelQueryTypes: null,
          isModelReadyToQuery: false,
          isWaitingForModelSchema: false,
          queryPollRetriesRemaining: Math.max(
            refreshPolling ? 60 : state.queryPollRetriesRemaining - 1,
            0
          ),
        };
      }

      //@ts-ignore
      const schema = res?.schema;
      console.log("flatSchema:", schema);
      const flatSchema = extractAttributes(schema);
      console.log("flatSchema:", flatSchema);
      const types = schema?.map((x: any) => ({ name: x._id }));
      console.log("types:", types);

      return {
        queryPollSchemaTimeout: null,
        modelQuerySchema: flatSchema,
        modelQueryTypes: types,
        isModelReadyToQuery: true,
        isWaitingForModelSchema: false,
      };
    } catch (ex: any) {
      console.error(ex);
      return {
        queryPollSchemaTimeout: null,
        modelQuerySchema: null,
        modelQueryTypes: null,
        isModelReadyToQuery: false,
        isWaitingForModelSchema: false,
      };
    }
  }
);

const setIsWaitingForModelQueryReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<boolean>
) => {
  state.isWaitingForModelQuery = payload;
};

const setlastSignUpEventSourceReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<string>
) => {
  state.lastSignUpEventSource = payload;
};

const setQueryModelCloudIdReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<string>
) => {
  state.queryModelCloudId = payload;
};

export const queryFile = createAsyncThunk(
  "ifcManager/queryFile",
  async (payload: undefined, { getState, dispatch }): Promise<Partial<IIfcMangerState>> => {
    const state = (getState() as RootState).ifcManager;

    if (state.isWaitingForModelSchema) return {};
    if (!state.modelQuerySelectedTypes || state.modelQuerySelectedTypes.length == 0) return {};
    if (!state.queryModelCloudId) return {};
    // use cached results
    if (state.modelQuerySelectGeneration == state.modelQueryResultGeneration) return {};

    const exportedAttributes = state.modelQueryExportedAttributes;
    const exportedAttributesKeys = Object.keys(exportedAttributes).filter(
      x => exportedAttributes[x] != undefined
    );
    const areAnyAttributesExported = exportedAttributesKeys.length > 0;
    if (!exportedAttributes || !areAnyAttributesExported) return {};

    const user = firebase.auth.currentUser;
    if (!firebase.storage || !user) {
      // Sentry.captureMessage("assertion broken: no user or firebase for queryFile");
      console.error("Invalid user");
      return {};
    }

    // todo: integrate thunk.pending into current state API
    dispatch(setIsWaitingForModelQuery(true));

    console.log(
      "exportedAttributesPaths:",
      exportedAttributesKeys.map(key => exportedAttributes[key])
    );
    console.log(
      "projection:",
      objectDeepMerge(
        {},
        exportedAttributesKeys.map(key => exportedAttributes[key])
      )
    );
    try {
      const response = await firebase.queryFile(
        state.queryModelCloudId,
        state.modelQuerySelectedTypes ?? [],
        objectDeepMerge(
          {},
          exportedAttributesKeys.map(key => exportedAttributes[key])
        )
      );
      const res = response?.data;
      console.log("res:", res);

      //@ts-ignore
      const elements = res?.elements;
      console.log("elements:", elements);
      return {
        modelQueryResult: elements ?? null,
        modelQueryResultGeneration: state.modelQuerySelectGeneration,
        isWaitingForModelQuery: false,
      };
    } catch (ex: any) {
      console.error(ex);
      return {
        isWaitingForModelQuery: false,
      };
    }
  }
);

const setWantsViewerNewsReducer = (state: IIfcMangerState, { payload }: PayloadAction<boolean>) => {
  state.wantsViewerNews = payload;
};

export const autodeskLinkRedirect = createAsyncThunk(
  "ifcManager/autodeskLinkRedirect",
  async () => {
    const stateNonce = crypto.randomUUID();
    localStorage.setItem("autodeskNonce", stateNonce);

    window.location.href = `https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code&client_id=${import.meta.env.REACT_APP_AUTODESK_CLIENT_ID}&redirect_uri=${import.meta.env.REACT_APP_AUTODESK_REDIRECT_URL}&scope=data:read%20account:read&state=${stateNonce}`;
  }
);

export const autodeskLinkAccount = createAsyncThunk(
  "ifcManager/autodeskLinkAccount",
  async (_payload, { dispatch }) => {
    const user = firebase.auth.currentUser;
    if (!firebase.storage || !user) {
      console.error("Invalid user");
      return undefined;
    }

    const url = new URLSearchParams(window.location.search);
    const code = url.get("code");
    const state = url.get("state");

    if (!code) {
      console.error("Invalid user");
      return undefined;
    }
    window.history.replaceState(null, "", "/");

    const autodeskNonce = localStorage.getItem("autodeskNonce");
    localStorage.removeItem("autodeskNonce");

    if (autodeskNonce != state) {
      dispatch(dismissModal());

      return (state: IIfcMangerState) => {
        state.snackbarSeverity = SEVERITIES.ERROR;
        state.snackbarMessage = "The autodesk sign in link has expired, please link again.";
      };
    }

    try {
      const response = await firebase.linkWithAutodesk(code);
      console.log("linkWithAutodesk::response:", response);

      dispatch(dismissModal());
      return (state: IIfcMangerState) => {
        state.snackbarSeverity = SEVERITIES.SUCCESS;
        state.snackbarMessage =
          "Your autodesk account has been linked! You can access it in the Cloud Library.";
      };
    } catch (ex) {
      console.error(ex);
      Sentry.captureException(ex, scope => scope.setLevel("log"));

      dispatch(dismissModal());
      return (state: IIfcMangerState) => {
        state.snackbarSeverity = SEVERITIES.ERROR;
        state.snackbarMessage = "Your autodesk account could't be linked. Try again later";
      };
    }
  }
);

const setAutodeskPendingUpdateReducer = (
  state: IIfcMangerState,
  { payload }: PayloadAction<{ entityHandle: AutodeskEntityHandle }>
) => {
  const parentId = payload.entityHandle.id || "root";
  state.autodeskPendingUpdates[parentId] = "loading";
};

export const autodeskPopulateChildren = createAsyncThunk(
  "ifcManager/autodeskPopulateChildren",
  async (payload: { entityHandle: AutodeskEntityHandle }, { dispatch }) => {
    const user = firebase.auth.currentUser;
    if (!firebase.storage || !user) {
      console.error("Invalid user");
      return undefined;
    }

    dispatch(setAutodeskPendingUpdate(payload));

    const parentId = payload.entityHandle.id;
    try {
      const response = await firebase.autodeskListEntityChildren(payload.entityHandle);
      const res = response?.data;

      //@ts-ignore
      const results = res?.results as AutodeskEntity[];

      return (state: IIfcMangerState) => {
        const newChildren: string[] = [];
        const parentEntity = parentId ? state.autodeskEntities[parentId] : {};

        if (results) {
          for (const result of results) {
            if (!result.id) continue;

            newChildren.push(result.id);
            const newEntity: AutodeskEntity = {
              ...parentEntity,
              //@ts-ignore
              children: state.autodeskEntities[result.id]?.children ?? [],
              ...result,
              ...(result.type == "hubs" && { hubId: result.id }),
              ...(result.type == "projects" && { projectId: result.id }),
              ...(result.type == "folders" && { folderId: result.id }),
              ...(result.type == "files" && { fileId: result.id }),
            };

            state.autodeskEntities[result.id] = newEntity;
          }
        }

        if (parentId) {
          state.autodeskEntities[parentId].children = newChildren;
        }

        delete state.autodeskPendingUpdates[parentId || "root"];
      };
    } catch (ex: any) {
      console.error(ex);
      return (state: IIfcMangerState) => {
        delete state.autodeskPendingUpdates[parentId || "root"];
      };
    }
  }
);

export const autodeskSyncFile = createAsyncThunk(
  "ifcManager/autodeskSyncFile",
  async (payload: { entityHandle: AutodeskEntityHandle }, { dispatch }) => {
    const user = firebase.auth.currentUser;
    if (!firebase.storage || !user) {
      console.error("Invalid user");
      return undefined;
    }

    const handle = payload.entityHandle;
    if (handle.type != "items") {
      console.error("Invalid entity type");
      return undefined;
    }
    if (handle.projectId == null || handle.id == null) {
      console.error("Invalid file identifiers");
      return undefined;
    }

    dispatch(setAutodeskPendingUpdate(payload));

    try {
      await firebase.autodeskSyncFile(handle.projectId, handle.id);

      return (state: IIfcMangerState) => {
        state.snackbarSeverity = SEVERITIES.SUCCESS;
        state.snackbarMessage = "Your file is synced! You can load it from the cloud library.";

        delete state.autodeskPendingUpdates[handle.id || "root"];
      };
    } catch (ex: any) {
      console.error(ex);
      Sentry.captureException(ex, scope => scope.setLevel("log"));

      return (state: IIfcMangerState) => {
        state.snackbarSeverity = SEVERITIES.ERROR;
        state.snackbarMessage = "Your file is couldn't be synced! Try again later.";

        delete state.autodeskPendingUpdates[handle.id || "root"];
      };
    }
  }
);

export const cloudSelectors = {
  selectIsWaitingServerResponse: (state: RootState) => state.ifcManager.isWaitingServerResponse,
  selectActiveAccountField: (state: RootState) => state.ifcManager.activeAccountField,
  selectIsServerNotReachable: (state: RootState) => state.ifcManager.isServerNotReachable,
  selectAuthProvider: (state: RootState) => state.ifcManager.authProvider,
  selectIsLoginPassMatch: (state: RootState) => state.ifcManager.isLoginPassMatch,
  selectIsNewPasswordStrong: (state: RootState) => state.ifcManager.isNewPasswordStrong,
  selectIsLoginSignInActive: (state: RootState) => state.ifcManager.isLoginSignInActive,
  selectIsLoginSignUpActive: (state: RootState) => state.ifcManager.isLoginSignUpActive,
  selectLoginName: (state: RootState) => state.ifcManager.loginName,
  selectLoginEmail: (state: RootState) => state.ifcManager.loginEmail,
  selectLoginPassword: (state: RootState) => state.ifcManager.loginPassword,
  selectLoginPasswordConfirmation: (state: RootState) => state.ifcManager.loginPasswordConfirmation,
  selectLoginNewPasswordConfirmation: (state: RootState) =>
    state.ifcManager.loginNewPasswordConfirmation,
  selectLoginNewPassword: (state: RootState) => state.ifcManager.loginNewPassword,
  selectAreChangesCloudSynced: (state: RootState) => state.ifcManager.areChangesCloudSynced,
  selectIsEmailConfirmed: (state: RootState) => state.ifcManager.isEmailConfirmed,
  selectQueryModelCloudId: (state: RootState) => state.ifcManager.queryModelCloudId,
  selectModelCloudId: (state: RootState) => state.ifcManager.modelCloudId,
  selectAccountFirstName: (state: RootState) => state.ifcManager.firstName,
  selectAccountLastName: (state: RootState) => state.ifcManager.lastName,
  selectAccountName: (state: RootState) => state.ifcManager.name,
  selectAccountIsLoggedIn: (state: RootState) => state.ifcManager.isLoggedIn,
  selectSavedModels: (state: RootState) => state.ifcManager.savedModels,
  selectCloudTotalSize: (state: RootState) =>
    state.ifcManager.savedModels.map(x => x.size).reduce((a, b) => a + b, 0),
  selectCloudStorageQuota: (state: RootState) => state.ifcManager.storageQuota,
  selectIsWaitingForUpload: (state: RootState) => state.ifcManager.isWaitingForUpload,
  selectIsWaitingForModelIdsDeletion: (state: RootState) =>
    state.ifcManager.isWaitingForModelIdsDeletion,
  selectIsWaitingForModelQuery: (state: RootState) => state.ifcManager.isWaitingForModelQuery,
  selectQueryPollRetriesRemaining: (state: RootState) => state.ifcManager.queryPollRetriesRemaining,
  selectWantsViewerNews: (state: RootState) => state.ifcManager.wantsViewerNews,
  selectAutodeskEntities: (state: RootState) => state.ifcManager.autodeskEntities,
  selectAutodeskPendingUpdates: (state: RootState) => state.ifcManager.autodeskPendingUpdates,
};

export const cloudReducers = {
  initAccounts: initAccountsReducer,
  updateLoginDetails: updateLoginDetailsReducer,
  setIsWaitingServerResponseAccount: setIsWaitingServerResponseAccountReducer,
  setIsWaitingForUpload: setIsWaitingForUploadReducer,
  setIsWaitingForModelIdDeletion: setIsWaitingForModelIdDeletionReducer,
  setIsWaitingForModelSchema: setIsWaitingForModelSchemaReducer,
  setIsWaitingForModelQuery: setIsWaitingForModelQueryReducer,
  setQueryModelCloudId: setQueryModelCloudIdReducer,
  setlastSignUpEventSource: setlastSignUpEventSourceReducer,
  setWantsViewerNews: setWantsViewerNewsReducer,
  setAutodeskPendingUpdate: setAutodeskPendingUpdateReducer,
};

export const cloudThunks = {
  uploadModel,
  listModels,
  saveCloudState,
  loginWithEmail,
  registerWithEmail,
  loginWithMicrosoft,
  loginWithGoogle,
  updatePassword,
  sendResetPasswordEmail,
  confirmEmail,
  relogin,
  saveAccountChanges,
  setActiveAccountField,
  checkServerConnection,
  deleteAccount,
  accountSignOut,
  queryFileSchema,
  queryFile,
  updateAccount,
};

export const cloudMutationThunks = {
  deleteModel,
  autodeskPopulateChildren,
  autodeskSyncFile,
  autodeskLinkRedirect,
  autodeskLinkAccount,
};
