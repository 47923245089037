import { Typography } from "@mui/material";
import { QuickstartDivider } from "app/components/quickstart/QuickstartDivider";

export default function QuickstartHome() {
  return (
    <>
      <QuickstartDivider />
      <Typography sx={{ maxWidth: "40rem", textAlign: "justify" }}>
        Sortdesk is a fast, browser-based platform for exploring and managing your IFC
        models—completely free and without registration. Privacy is key: when you don’t sign in,
        your data remains local, never uploaded to a server.
        <br />
        <br /> With tools like the section tool, measurement tool, plan manager, and object tree
        navigation, you can easily explore and analyze your models, all from your browser.
        <br />
        <br />
        Sign up for a free account to unlock advanced features like the IDS Editor, IFC validation,
        and model syncing across devices.
        <br />
        <br />
        This guided tour will introduce you to each feature step-by-step.
      </Typography>
      <QuickstartDivider />
      <Typography sx={{ width: "100%", textAlign: "center" }}>
        Click <b>Next</b> to start with the Section Tool and see how easy it is to cut through your
        models.
      </Typography>
    </>
  );
}
