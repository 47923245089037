// Import the functions you need from the SDKs you need
import { AutodeskEntityHandle } from "app/common/types";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  updateEmail,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";

import { initializeFirebaseEmulator } from "./firebase.emulator";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  authDomain: import.meta.env.REACT_APP_FIREBASE_authDomain,
  apiKey: import.meta.env.REACT_APP_FIREBASE_apiKey,
  projectId: import.meta.env.REACT_APP_FIREBASE_projectId,
  storageBucket: import.meta.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: import.meta.env.REACT_APP_FIREBASE_messagingSenderId,
  appId: import.meta.env.REACT_APP_FIREBASE_appId,
  measurementId: import.meta.env.REACT_APP_FIREBASE_measurementId,
};

export type RunIDSReportArgs = {
  idsSource: string;
  ifcSource?: string;
  ifcModelCloudId?: string;
};

export type SubscribeToNewsletterArgs = {
  email: string;
  feature: "wantsViewerNews" | "wantsIDSBetaNews";
};

// source: https://github.com/firebase/firebase-js-sdk/blob/7709f1016d6e491847bb4e8e4c731603d47f7eae/packages/functions/src/error.ts#L119
export enum FexCodes {
  invalidArgument = "functions/invalid-argument", // 400
  unauthenticated = "functions/unauthenticated", // 401
  permissionDenied = "functions/permission-denied", // 403
  notFound = "functions/not-found", // 404
  aborted = "functions/aborted", // 409
  resourceExhausted = "functions/resource-exhausted", // 429
  cancelled = "functions/cancelled", // 499
  internal = "functions/internal", // 500
  unimplemented = "functions/unimplemented", // 501
  unavailable = "functions/unavailable", // 503
  deadlineExceeded = "functions/deadline-exceeded", // 504
}

export type FirebaseFuncError = {
  name: "FirebaseError";
  code?: FexCodes;
  message?: string;
  details?: any; // this is customData
};

const initializeFirebase = () => {
  console.log("env.MODE:", import.meta.env.MODE);
  console.log("firebaseConfig:", firebaseConfig);

  const app = initializeApp(firebaseConfig);

  if (import.meta.env.MODE == "dev") {
    return {
      updateProfile,
      updateEmail,
      updatePassword,
      onAuthStateChanged,
      ...initializeFirebaseEmulator(),
    };
  }

  const auth = getAuth(app);
  const functions = getFunctions(app, "europe-west6");
  const storage = getStorage(app);
  const db = getFirestore(app);

  const analyticsState = {
    initialized: false,
    analytics: null as Analytics | null,
  };

  const initializeAnalytics = () => {
    analyticsState.analytics = getAnalytics(app);
    analyticsState.initialized = true;
  };

  const ga_event = (eventName: string, eventParams?: { [key: string]: any }) => {
    if (!analyticsState.analytics) return;
    logEvent(analyticsState.analytics, eventName, eventParams);
  };

  const proxyGetFile = (url: string) => {
    return httpsCallable(functions, "proxy")({ url });
  };

  const queryFile = (modelID: string, elementTypes: string[], attributePaths: any) => {
    return httpsCallable(functions, "queryFile")({ modelID, elementTypes, attributePaths });
  };

  const queryFileSchema = (modelID: string) => {
    return httpsCallable(functions, "queryFileSchema")({ modelID });
  };

  const deleteAccount = () => {
    return httpsCallable(functions, "deleteAccount")({});
  };

  const runIDSReport = ({ idsSource, ifcSource, ifcModelCloudId }: RunIDSReportArgs) => {
    // backend timeout is 180s
    return httpsCallable(functions, "runIDSReport", { timeout: 1000000 })({
      idsSource,
      ifcSource,
      ifcModelCloudId,
    });
  };

  const subscribeToNewsletter = ({ email, feature }: SubscribeToNewsletterArgs) => {
    return httpsCallable(
      functions,
      "subscribeToNewsletter"
    )({
      email,
      feature,
    });
  };

  const linkWithAutodesk = (code: string) => {
    return httpsCallable(functions, "linkWithAutodesk")({ code });
  };

  const autodeskListEntityChildren = (entityHandle: AutodeskEntityHandle) => {
    return httpsCallable(functions, "autodeskListEntityChildren")(entityHandle);
  };

  const autodeskSyncFile = (projectId: string, fileId: string) => {
    return httpsCallable(functions, "autodeskSyncFile")({ projectId, fileId });
  };

  console.log("firebase initialized!");

  return {
    auth,
    functions,
    storage,
    db,
    initializeAnalytics,
    ga_event,
    proxyGetFile,
    deleteAccount,
    queryFile,
    queryFileSchema,
    updateProfile,
    updateEmail,
    updatePassword,
    onAuthStateChanged,
    runIDSReport,
    subscribeToNewsletter,
    linkWithAutodesk,
    autodeskListEntityChildren,
    autodeskSyncFile,
  };
};

export const firebase = initializeFirebase();
