import { BoxProps, Typography } from "@mui/material";
import { QUICKSTART_SECTIONS } from "app/common/types";
import PropertiesSidebar from "app/components/properties/PropertiesSidebar";
import { QuickstartNextPrev } from "app/components/quickstart/QuickstartNextPrev";
import ToolsSidebar from "app/components/ToolsSideBar";
import Layout from "app/Layout";
import OpenAgreementModal from "app/modals/OpenAgreementModal";
import PolicyModal from "app/modals/policies/PolicyModal";
import { QuickstartContext } from "app/state/contexts";
import theme from "app/theme";
import { Helmet } from "react-helmet";
import { BoxCol } from "app/components/common/BoxCol";

import { quickstartMeta, quickstartOrder } from "./quickstart/quickstartMeta";

export default function QuickstartPage(props: BoxProps & { section: QUICKSTART_SECTIONS }) {
  const { section, sx, ...otherProps } = props;

  const orderIndex = quickstartOrder.findIndex(x => x == section);
  const sectionMeta = quickstartMeta[section];
  const SectionComponent = sectionMeta.component;
  return (
    <QuickstartContext.Provider value={true}>
      <Layout>
        <Helmet>
          <title>{`Sortdesk | ${sectionMeta.title} Quickstart - Free Online IFC Viewer`}</title>
          <meta name="description" content={sectionMeta.tagline} />
        </Helmet>
        <OpenAgreementModal />
        <PolicyModal />
        <PropertiesSidebar />
        <ToolsSidebar />
        <BoxCol
          sx={{
            marginTop: theme.sppx.headerHeight,
            marginBottom: theme.sppx.footerHeight,
            marginLeft: theme.sppx.sidebarWidth,
            marginRight: theme.sppx.sidebarWidth,
            padding: theme.pad.spacious,
            textAlign: "justify",
            alignItems: "center",
            ...sx,
          }}
          {...otherProps}
        >
          <Typography
            sx={{
              marginTop: theme.sppx.spaciousH,
              // marginBottom: theme.sppx.com,
              textAlign: "center",
            }}
            component="h1"
          >
            <Typography
              component="span"
              sx={{
                fontSize: "2rem",
                fontWeight: "600",
              }}
            >
              {sectionMeta.taglineBold}
            </Typography>
            {sectionMeta?.taglineHasNewline && (
              <>
                <br />
              </>
            )}
            <Typography
              component="span"
              sx={{
                fontSize: "2rem",
                fontWeight: "400",
              }}
            >
              {sectionMeta.tagline}
            </Typography>
          </Typography>
          <BoxCol sx={{ width: "100%", alignItems: "center" }}>
            <SectionComponent />
          </BoxCol>
          <QuickstartNextPrev
            orderIndex={orderIndex}
            sx={{
              marginTop: theme.sppx.spaciousH,
            }}
          />
        </BoxCol>
      </Layout>
    </QuickstartContext.Provider>
  );
}
