import { Typography } from "@mui/material";
import { TOOLS } from "app/common/types";
import { QuickstartDivider } from "app/components/quickstart/QuickstartDivider";
import { useAppDispatch } from "app/state/hooks";
import { setTool } from "app/state/slices/ifcManagerSlice";
import { useEffect } from "react";

export default function QuickstartQuery() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTool({ selectedTool: TOOLS.QUERY }));
  }, []);

  return (
    <>
      <QuickstartDivider />
      <Typography sx={{ maxWidth: "40rem", textAlign: "justify" }}>
        With our Data Extraction tool, you can easily convert and export key information from your
        IFC models to Excel or CSV files. Whether you need quantities, properties, or other
        essential data, this feature allows you to quickly generate organized spreadsheets for
        further analysis or reporting. <br />
        <br />
        It&apos;s a simple way to streamline your workflow and get the most out of your IFC
        data—perfect for tasks like quantity take-offs or data management.
      </Typography>
      <QuickstartDivider />
      <Typography sx={{ width: "100%", textAlign: "center" }}>
        Click <b>Next</b> to navigate the Object Tree and explore your models more efficiently.
      </Typography>
    </>
  );
}
