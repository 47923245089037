// import { IfcPropertiesManager } from "openbim-components";
import { FragmentsGroupExtended, ViewerSingleton } from "app/common/ViewerAPI";
import { PlanView } from "app/components/ifcjs/front";
import { computeBoundingSphere } from "app/utils";
import { firebase } from "app/utils/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getBytes, getMetadata, ref, uploadBytes } from "firebase/storage";
// import { PlanView } from "app/components/ifcjs/FragmentPlans";
import * as Sentry from "@sentry/react";
import { Plan, SpatialNode, SpatialNodesMap } from "app/common/types";
import { ifcTypeNames } from "app/common/webIfcReverseSchema";
import { IfcJsonExporter } from "app/components/ifcjs/core";
import { FragmentsGroup, IfcProperties } from "app/components/ifcjs/fragments";
import { cloudErrors } from "app/state/slices/ifcManager/cloud";
import { perfMeasure } from "app/utils/perfMeasure";
import * as THREE from "three";
import * as WEBIFC from "web-ifc";

import { _cameraGoHome, _fitModelToFrame } from "./camera";
// import { _clearHighlightedSelection } from "./properties";

const latestBIMTilesVersion = 2;
type BIMTilesMeta = {
  properties: IfcProperties | undefined;
  geometryDescriptor: Record<string, string>;
  chunks: Record<string, { offset: number; length: number; isGeometryGroup: boolean }>;
  propertiesRelations: any; // a serializable version of RelationsMap
};

export const buildandUploadBIMTilesBinary = async ({
  model,
  storageID,
}: {
  model: FragmentsGroup;
  storageID: string;
}) => {
  const viewer = ViewerSingleton.getInstance();

  const user = firebase.auth.currentUser;
  if (!user) {
    return;
  }

  const metadata: BIMTilesMeta = {
    properties: model._properties,
    geometryDescriptor: viewer._bimTileState.geometryDescriptor,
    chunks: {},
    propertiesRelations: viewer._propsIndexer.serializeModelRelations(model),
  };

  const chunks: { url: string; data: Uint8Array; offset: number; isGeometryGroup: boolean }[] = [];
  let total_length = 0;

  for (const [url, data] of Object.entries(viewer._bimTileState.geometryFiles)) {
    chunks.push({ url, data, offset: total_length, isGeometryGroup: false });
    total_length += data.length;
  }
  for (const [url, data] of Object.entries(viewer._bimTileState.geometryGroup)) {
    chunks.push({ url, data, offset: total_length, isGeometryGroup: true });
    total_length += data.length;
  }

  const chunks_serilized = new Uint8Array(total_length);
  for (const chunk of chunks) {
    chunks_serilized.set(chunk.data, chunk.offset);

    metadata.chunks[chunk.url] = {
      offset: chunk.offset,
      length: chunk.data.length,
      isGeometryGroup: chunk.isGeometryGroup,
    };
  }

  const metadata_serialized = JSON.stringify(metadata);

  const bimTilesStorageRef = ref(firebase.storage, `/spaces/${user.uid}/${storageID}-bimtiles`);
  const bimTilesMetaStorageRef = ref(
    firebase.storage,
    `/spaces/${user.uid}/${storageID}-bimtiles_meta.json`
  );
  const dbFileRef = doc(firebase.db, `/spaces/${user.uid}/files`, `${storageID}`);

  /* update storage */
  try {
    await uploadBytes(bimTilesStorageRef, chunks_serilized);
    const enc = new TextEncoder();
    await uploadBytes(bimTilesMetaStorageRef, enc.encode(metadata_serialized));
    /* update database entry */
    await setDoc(dbFileRef, { bimTilesVersion: latestBIMTilesVersion }, { merge: true });
  } catch (ex: any) {
    console.error(ex);
    // .name: "FirebaseError"
    if (ex?.status == 413 && ex?.customData?.serverResponse == "Payload Too Large") {
      return { isWaitingForUpload: false, ...cloudErrors.fileTooLarge };
    }
    // note: this covers all cases in storage.rules: !permission|>size|!token
    if (
      ex?.status == 403 &&
      ex?.customData?.serverResponse == "Permission denied. No WRITE permission."
    ) {
      return { isWaitingForUpload: false, ...cloudErrors.noWriting };
    }

    Sentry.captureException(ex);
    return { isWaitingForUpload: false, ...cloudErrors.uploadFailed };
  }
};

export const _loadIfcFile = async (
  file?: File,
  url?: string,
  directUrl?: string,
  storageId?: string,
  //fixme: toc update
  //eslint-disable-next-line
  isPartial?: boolean
) => {
  const isLoadFromURL = false;
  const isLoadProperties = true;
  let isLoadWithStreamer = false;

  const m = perfMeasure("loadUnload");

  const viewer = ViewerSingleton.getInstance();

  let buffer: Uint8Array | null = null;
  let bimTilesBuffer: Uint8Array | null = null;
  let bimTilesMeta: BIMTilesMeta | null = null;
  let modelName: string | null = null;
  let bimTilesVersion: number | null = null;
  let modelUploadedTime: string | null = null;

  if (url) {
    console.log("_loadIfcFile::proxyGetFile");
    const response = (await firebase.proxyGetFile(url)).data as {
      fileData: string;
      message: string;
    };
    buffer = new TextEncoder().encode(response.fileData);
    modelName = url.substring(url.lastIndexOf("/") + 1);
    m.lap("_loadIfcFile::proxyGetFile");
  } else if (directUrl) {
    console.log("_loadIfcFile::fetchGetFile");
    const data = await (await fetch(directUrl)).arrayBuffer();
    buffer = new Uint8Array(data);
    modelName = directUrl.substring(directUrl.lastIndexOf("/") + 1);
    m.lap("_loadIfcFile::proxyGetFile");
  } else if (file) {
    console.log("_loadIfcFile::file");
    const data = await file.arrayBuffer();
    buffer = new Uint8Array(data);
    modelName = file.name;
    m.lap("_loadIfcFile::file");
  } else if (storageId) {
    console.log("_loadIfcFile::storageId");
    const user = firebase.auth.currentUser;
    if (!firebase.storage || !user) return;

    const storageRef = ref(firebase.storage, `/spaces/${user.uid}/${storageId}`);

    const metadata = await getMetadata(storageRef);
    console.log("file metadata:", metadata);
    modelUploadedTime = metadata.timeCreated;

    const filesRef = doc(firebase.db, `/spaces/${user.uid}/files`, `${storageId}`);
    const fileProps = (await getDoc(filesRef)).data();

    modelName = fileProps?.name ?? null;
    bimTilesVersion = fileProps?.bimTilesVersion ?? null;

    if (bimTilesVersion !== latestBIMTilesVersion) {
      console.log("_loadIfcFile::bimTilesVersion:", bimTilesVersion);
      buffer = new Uint8Array(await getBytes(storageRef));
      m.lap("_loadIfcFile::cloud file");
    } else {
      console.log("_loadIfcFile::bimTilesVersion:", bimTilesVersion);
      const bimTilesStorageRef = ref(firebase.storage, `/spaces/${user.uid}/${storageId}-bimtiles`);
      const bimTilesMetaStorageRef = ref(
        firebase.storage,
        `/spaces/${user.uid}/${storageId}-bimtiles_meta.json`
      );

      const dec = new TextDecoder();
      bimTilesBuffer = new Uint8Array(await getBytes(bimTilesStorageRef));
      const metaString = dec.decode(await getBytes(bimTilesMetaStorageRef));
      m.lap("_loadIfcFile::cloud tiles get");
      bimTilesMeta = JSON.parse(metaString);
      isLoadWithStreamer = true;
      m.lap("_loadIfcFile::cloud tiles parsed");
    }
    m.lap("_loadIfcFile::storageId");
  } else {
    console.error("loadIfcFile called without file or url");
    return;
  }

  modelName ??= "No file name";
  let properties: IfcProperties | null = null;

  let webIfc: WEBIFC.IfcAPI | null = null;

  if (buffer) {
    console.log("_loadIfcFile::load from buffer");

    // no bimTilesMeta was found

    /* readIfcFile a single time, reuse instance for properties */
    const { path, absolute, logLevel } = viewer._geometryTiler.settings.wasm;
    webIfc = new WEBIFC.IfcAPI();
    webIfc.SetWasmPath(path, absolute);
    await webIfc.Init();
    if (logLevel) {
      webIfc.SetLogLevel(logLevel);
    }
    webIfc.OpenModel(buffer, viewer._geometryTiler.settings.webIfc);

    // if (!isLoadWithStreamer) {
    viewer._geometryTilerIsProcessing.reset();
    await viewer._geometryTiler.streamFromLoadedFile(webIfc);
    await viewer._geometryTilerIsProcessing.done;

    m.lap("geometry processed");
    if (isLoadProperties) {
      const jsonExporter = viewer._components.get(IfcJsonExporter);
      properties = await jsonExporter.export(webIfc, 0);

      // viewer._propsTilerIsProcessing.reset();
      // await viewer._propsTiler.streamFromBuffer(buffer);
      // await viewer._propsTilerIsProcessing.done;
    }
    m.lap("properties processed");
    isLoadWithStreamer = true;
  } else if (bimTilesMeta && bimTilesBuffer) {
    console.log("_loadIfcFile::load from cloud tiles");

    properties = bimTilesMeta.properties ?? null;
    for (const url in bimTilesMeta.chunks) {
      const chunkMeta = bimTilesMeta.chunks[url];
      const chunkData = bimTilesBuffer.slice(chunkMeta.offset, chunkMeta.offset + chunkMeta.length);
      if (chunkMeta.isGeometryGroup) {
        viewer._bimTileState.geometryGroup[url] = chunkData;
      } else {
        viewer._bimTileState.geometryFiles[url] = chunkData;
      }
      console.log(`url:${url}, chunk_meta:`, chunkMeta);
    }
    m.lap("bimTilesMeta processed");
  }

  viewer.modelSrc = buffer;
  let modelTemp = null as FragmentsGroupExtended | null;
  if (isLoadWithStreamer) {
    // generate geometry tiles
    let geometryDescriptor: any = undefined;
    if (isLoadFromURL) {
      const raw = await fetch("/small.ifc-processed.json");
      geometryDescriptor = await raw.json();
    } else if (bimTilesMeta) {
      console.log("_loadIfcFile::geometryDescriptor: cloud");
      geometryDescriptor = bimTilesMeta.geometryDescriptor["small.ifc-processed.json"];
    } else {
      console.log("_loadIfcFile::geometryDescriptor: local");
      geometryDescriptor = viewer._bimTileState.geometryDescriptor["small.ifc-processed.json"];
    }
    m.lap("geometryDescriptor processed");

    // generate property tiles
    const propsDescriptor: any = undefined;
    // if (isLoadProperties) {
    //   if (isLoadFromURL) {
    //     const rawPropertiesData = await fetch("/small.ifc-processed-properties.json");
    //     propsDescriptor = await rawPropertiesData.json();
    //   } else {
    //     propsDescriptor = JSON.parse(
    //       viewer._bimTileState.propertyDescriptor["small.ifc-processed-properties.json"]
    //     );
    //   }
    // }

    modelTemp = (await viewer._ifcStreamer.load(
      geometryDescriptor,
      true,
      propsDescriptor
    )) as FragmentsGroupExtended;

    // preload all geometry tiles
    // const geometryFiles = viewer._bimTileState.geometryFiles;
    // for (const fileName in geometryFiles) {
    //   viewer._ifcStreamer.updateRamCacheWithFile(viewer._ifcStreamer.url + fileName, geometryFiles[fileName]);
    // }
  } else if (buffer) {
    modelTemp = (await viewer._ifcLoader.load(buffer)) as FragmentsGroupExtended;
  }
  if (modelTemp == null) {
    throw new Error("Could not load model");
  }
  m.lap("load_model");

  const model = modelTemp;
  model.name = modelName;
  viewer._world.scene.three.add(model);

  let boundingSphere: THREE.Sphere | null = null;

  if (isLoadWithStreamer) {
    // with culling disabled it's very laggy
    // console.log("viewer._ifcStreamer.fragIDData:", viewer._ifcStreamer.fragIDData);
    // await viewer._ifcStreamer.setStatic(viewer._ifcStreamer.fragIDData.keys(), true, true);
    // await viewer._ifcStreamer.setStatic(viewer._ifcStreamer.fragIDData.keys(), false, true);

    await viewer._ifcStreamer.preloadFragments(viewer._ifcStreamer.fragIDData.keys(), () => {
      boundingSphere = computeBoundingSphere(model);
    });
  }

  m.lap("preloadFragments");
  /* properties */ {
    try {
      if (properties) {
        model.setLocalProperties(properties);
      }
      // if (buffer && webIfc) {
      // fixme: of course this is broken too
      // viewer._propsIndexer.processFromWebIfc(webIfc, model.id);
      // } else {
      // viewer._propsIndexer.process(model);
      // }
      if (bimTilesMeta?.propertiesRelations) {
        const relationsMap = viewer._propsIndexer.getRelationsMapFromObject(
          bimTilesMeta?.propertiesRelations
        );
        viewer._propsIndexer.setRelationMap(model, relationsMap);
      } else {
        viewer._propsIndexer.process(model);
      }
    } catch (ex) {
      console.error(ex);
      Sentry.captureException(ex, scope => scope.setLevel("log"));
    }
    // viewer._propsProcessor.process(model);
  }
  m.lap("process properties");

  for (const fragment of model.items) {
    // needed to be rendered
    viewer._world.meshes.add(fragment.mesh);
    const geometry = fragment.mesh.geometry;

    // needed by the plan generator
    // @ts-ignore
    if (!geometry.boundsTree) {
      // @ts-ignore
      geometry.computeBoundsTree();
    }

    let isSpace = false;
    if (properties) {
      for (const expressID of fragment.instanceToItem.values()) {
        if (properties[expressID]?.type == WEBIFC.IFCSPACE) {
          isSpace = true;
          break;
        }
      }
    }
    // IFCSPACE fills block the view to the room when sectioning
    if (!isSpace) {
      // needed by plan outlines
      for (const styleName in viewer._edges.styles.list) {
        const style = viewer._edges.styles.list[styleName];
        style.meshes.add(fragment.mesh);
      }
    } else {
      // some models have only spaces, plans are not very readble without edges
      const style = viewer._edges.styles.list["IFCSPACEStyle"];
      style.meshes.add(fragment.mesh);
    }

    if (viewer?._culler) {
      // needed by screen culler
      viewer._culler.add(fragment.mesh);
      viewer._culler.needsUpdate = true;
    }
  }
  m.lap("process_fragments");

  // also needed by ifc streamer geometry culler
  const controls = viewer._camera.controls;
  controls.addEventListener("controlend", () => {
    viewer._highlighter.config.autoHighlightOnHover = true;

    if (viewer?._culler) viewer._culler.needsUpdate = true;
    viewer._ifcStreamer.culler.needsUpdate = true;
    viewer._renderer.needsUpdate = true;
    viewer._navGizmo.needsUpdate = true;
  });
  controls.addEventListener("controlstart", () => {
    viewer._highlighter.config.autoHighlightOnHover = false;
    viewer._renderer.needsUpdate = true;
    viewer._navGizmo.needsUpdate = true;
  });
  controls.addEventListener("update", () => {
    viewer._highlighter.config.autoHighlightOnHover = false;
    if (viewer?._culler) viewer._culler.needsUpdate = true;
    viewer._ifcStreamer.culler.wantsUpdate = true;
    viewer._renderer.needsUpdate = true;
    viewer._navGizmo.needsUpdate = true;
  });
  controls.addEventListener("control", () => {
    viewer._highlighter.config.autoHighlightOnHover = false;
    if (viewer?._culler) viewer._culler.needsUpdate = true;
    viewer._ifcStreamer.culler.wantsUpdate = true;
    viewer._renderer.needsUpdate = true;
    viewer._navGizmo.needsUpdate = true;
  });
  controls.addEventListener("sleep", () => {
    viewer._highlighter.config.autoHighlightOnHover = true;
    if (viewer?._culler) viewer._culler.needsUpdate = true;
    viewer._ifcStreamer.culler.needsUpdate = true;
    viewer._renderer.needsUpdate = true;
    viewer._navGizmo.needsUpdate = true;
  });

  const tjsScene = viewer._scene.three;
  if (!tjsScene.children.some(obj => ["DirectionalLight", "AmbientLight"].includes(obj.type))) {
    viewer._scene.setup({
      directionalLight: {
        color: new THREE.Color("white"),
        intensity: 2,
        position: new THREE.Vector3(5, 10, 3),
      },
      ambientLight: {
        color: new THREE.Color("white"),
        intensity: 1.5,
      },
    });
  }

  viewer._scene.three.background = null;

  m.lap("scene setup");

  // remove placeholder grid and axes
  viewer._grid.list.forEach(grid => (grid.visible = false));

  // generate plans
  let plans: PlanView[] = [];
  try {
    //   viewer._classifier.byEntity(model);
    //   viewer._classifier.byStorey(model);

    //   {
    //     const found = await viewer._classifier.find({
    //       entities: ["IFCWALLSTANDARDCASE", "IFCWALL"],
    //     });
    //     const styles = viewer._clipper.styles.get();

    //     for (const fragID in found) {
    //       const { mesh } = viewer._fragments.list[fragID];
    //       styles.filled.fragments[fragID] = new Set(found[fragID]);
    //       styles.filled.meshes.add(mesh);
    //     }

    //     const meshes = [];
    //     for (const fragment of model.items) {
    //       const { mesh } = fragment;
    //       meshes.push(mesh);
    //       styles.projected.meshes.add(mesh);
    //     }
    //   }

    {
      viewer._plans.generate(model);
      const computedPlans = viewer?._plans?.list;
      if (computedPlans != undefined) plans = computedPlans;
    }
  } catch (err: unknown) {
    /* the object can still be viewed, ignore for now, not all are buildings */
    console.error("Plan views could not be generated", err);
  }

  m.lap("plans");
  const alwaysHiddenItems = new Set<number>();
  /* object tree */ {
    const spatialNodesMap: SpatialNodesMap = {};
    const spatialTreeRoots: SpatialNode[] = [];
    const modelUUID = model.uuid;
    spatialNodesMap[modelUUID] = {};
    const currentSpatialNodesMap = spatialNodesMap[modelUUID];
    // this is used to find the roots of (potentially) unconnected trees (ideally just an IfcSite tree)
    const hasAParentSet = new Set<string>();

    try {
      const spatialRels = model.getAllPropertiesOfType(WEBIFC.IFCRELCONTAINEDINSPATIALSTRUCTURE);
      const aggregateRels = model.getAllPropertiesOfType(WEBIFC.IFCRELAGGREGATES);

      m.lap("get spatial rels");
      const addNodes = (parentID: string | null, childIDs: any[]) => {
        if (parentID) {
          if (!(parentID in currentSpatialNodesMap)) {
            const properties = model.getProperties(Number(parentID));
            currentSpatialNodesMap[parentID] = {
              name: properties?.Name?.value,
              id: `${modelUUID}/${parentID}`,
              children: [],
              metadata: {
                type: ifcTypeNames[String(properties?.type)],
                isVisible: true,
                expressID: parentID,
                modelUUID,
                fragmentIDs: [],
                expressIDs: [],
              },
            };
            if (properties?.type == WEBIFC.IFCSPACE) {
              alwaysHiddenItems.add(Number(parentID));
            }
          }

          const parentNode = currentSpatialNodesMap[parentID];

          for (const child of childIDs) {
            const childIDNum = child?.value;
            if (childIDNum) {
              const childID = String(childIDNum);
              hasAParentSet.add(childID);

              if (!(String(childID) in currentSpatialNodesMap)) {
                const properties = model.getProperties(childIDNum);
                currentSpatialNodesMap[childID] = {
                  name: properties?.Name?.value,
                  id: `${modelUUID}/${childID}`,
                  children: [],
                  metadata: {
                    type: ifcTypeNames[String(properties?.type)],
                    isVisible: true,
                    expressID: childID,
                    modelUUID: modelUUID,
                    fragmentIDs: [],
                    expressIDs: [],
                  },
                };
              }
              if ((properties as any)?.type == WEBIFC.IFCSPACE) {
                alwaysHiddenItems.add(Number(childIDNum));
              }

              const childNode = currentSpatialNodesMap[childID];
              if (parentNode.children) {
                // this children check is just to satify TS, it always has
                if (!parentNode.children.includes(childNode)) {
                  parentNode.children.push(childNode);
                }
              }
            }
          }
        }
      };

      // const spatialRelsSize = spatialRels?.size();
      for (const id in spatialRels) {
        //@ts-ignore
        const properties = spatialRels[
          id as unknown as number
        ] as WEBIFC.IFC2X3.IfcRelContainedInSpatialStructure;
        if (!properties) {
          continue;
        }

        //@ts-ignore
        const parentID = properties?.RelatingStructure?.value;
        const childIDs = properties?.RelatedElements; // IfcProduduct+
        addNodes(String(parentID), childIDs);
        // console.log("addNodes: String(parentID), childIDs:", String(parentID), childIDs);
      }

      for (const id in aggregateRels) {
        //@ts-ignore
        const properties = aggregateRels[id as unknown as number] as WEBIFC.IFC2X3.IfcRelAggregates;
        if (!properties) {
          continue;
        }

        //@ts-ignore
        const parentID = properties?.RelatingObject?.value;
        const childIDs = properties?.RelatedObjects;
        addNodes(String(parentID), childIDs);
      }

      for (const expressID in currentSpatialNodesMap) {
        const node = currentSpatialNodesMap[expressID];
        // completely remmove empty arrays => !children == isLeaf
        if (node.children?.length == 0) {
          delete node.children;
        }

        if (!hasAParentSet.has(expressID)) {
          spatialTreeRoots.push(currentSpatialNodesMap[expressID]);
        }
      }

      //Note: not working fragments in viewer._ifcStreamer.fragIDData
      /* cache the fragment ids inside each node */
      for (const [fragmentID, fragment] of viewer._ifcStreamer.allLoadedFragments) {
        for (const expressID of fragment.ids) {
          if (!(expressID in currentSpatialNodesMap)) {
            console.log("could not find in currentSpatialNodesMap the expressID:", expressID);
            continue;
          }
          const metadata = currentSpatialNodesMap[expressID].metadata;
          metadata.fragmentIDs.push(fragmentID);
          metadata.expressIDs.push(expressID);
        }
      }

      model.spatialNodesMap = spatialNodesMap;
      model.spatialTreeRoots = spatialTreeRoots;
    } catch (ex: any) {
      console.error("Could not generate spatial tree:", ex);
    }
  }

  m.lap("object tree");

  // Vector3 is not serialiazble for the redux state
  if (!boundingSphere) {
    boundingSphere = new THREE.Sphere(new THREE.Vector3(0, 0, 0), 10);
  }
  const boundingSphereCenter = [
    boundingSphere.center.x,
    boundingSphere.center.y,
    boundingSphere.center.z,
  ];

  viewer._boundingSphereCenter = boundingSphereCenter;
  viewer._boundingSphereRadius = boundingSphere.radius;
  m.lap("bounding sphere");

  // for (const spaceExpressID of alwaysHiddenItems) {
  //   viewer.setVisibilityByID({
  //     modelUUID: model.uuid,
  //     expressID: String(spaceExpressID),
  //     value: false,
  //   })
  // }

  if (webIfc != null) {
    webIfc.Dispose();
  }

  viewer._renderer.resize();
  _cameraGoHome();
  _fitModelToFrame();
  // if (isPartial) {
  //   viewer._clipper.updateEdges(true);
  // }

  m.lap("final details");

  if (storageId && bimTilesVersion !== latestBIMTilesVersion) {
    await buildandUploadBIMTilesBinary({ model, storageID: storageId });
  }

  viewer._ifcStreamer.culler.needsUpdate = true;

  return {
    fileName: modelName,
    modelUploadedTime,
    modelID: model.id,
    modelUUID: model.uuid,
    modelCloudId: storageId ?? null,
    areChangesCloudSynced: Boolean(storageId),
    plans: plans.map(x => ({ modelID: String(model.id), id: x.id, name: x.name }) as Plan),
  };
};

export const _unloadAll = () => {
  ViewerSingleton.reset();
  const viewer = ViewerSingleton.getInstance();
  viewer._renderer.resize();
};

export const _unloadAllSync = async () => {
  await ViewerSingleton.resetSync();
  const viewer = ViewerSingleton.getInstance();
  viewer._renderer.resize();
};

//fixme: toc update
//eslint-disable-next-line
export const _unloadOne = async (modelID: string) => {
  // const viewer = ViewerSingleton.getInstance();
  // _clearHighlightedSelection();
  // await viewer._fragmentIfcLoader.unload(modelID);
  // await viewer._clipper.updateEdges(true);
};
