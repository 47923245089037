import { Link, Typography } from "@mui/material";
import { MODALS, TOOLS } from "app/common/types";
import { QuickstartDivider } from "app/components/quickstart/QuickstartDivider";
import { useAppDispatch } from "app/state/hooks";
import {
  setlastSignUpEventSource,
  setSelectedModal,
  setTool,
} from "app/state/slices/ifcManagerSlice";
import { useEffect } from "react";

export default function QuickstartProperties() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTool({ selectedTool: TOOLS.PROPERTIES }));
  }, []);

  return (
    <>
      <QuickstartDivider />
      <Typography sx={{ maxWidth: "40rem", textAlign: "justify" }}>
        The Properties tool allows you to view and analyze the detailed attributes of any object in
        your IFC model. Simply select an object, and instantly access its properties, including
        dimensions, materials, and classifications—all in one convenient location. <br />
        <br />
        This feature is ideal for gaining insights into the specific components of your model and
        ensuring compliance with project standards.
      </Typography>
      <QuickstartDivider />
      <Typography sx={{ maxWidth: "40rem", textAlign: "justify" }}>
        That concludes your tour!
        <br />
        <br /> Ready to unlock more advanced features like cloud sync and enhanced model management?{" "}
        <Link
          href="#"
          onClick={() => {
            dispatch(setlastSignUpEventSource("queryPanel"));
            dispatch(setSelectedModal(MODALS.LOGIN));
          }}
        >
          Sign up for a free account
        </Link>{" "}
        and take full advantage of Sortdesk&apos;s capabilities.
        <br />
        <br />
        Want to explore more?{" "}
        <Link href="/?sample=rme_advanced_sample_project.ifc">
          Click here to load sample data
        </Link>{" "}
        and start experimenting with our tools right away.
      </Typography>
    </>
  );
}
