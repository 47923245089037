import { PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { VieweAPIState, ViewerSingleton, viewerAPI } from "app/common/ViewerAPI";
import { MODALS, ModelID, SEVERITIES, TOOLS } from "app/common/types";
import { RootState } from "app/state/store";
import { objectRecAccess } from "app/utils/objectMeta";

import { IIfcMangerState, setIsViewerDisplayed, setSelectedModal } from "../ifcManagerSlice";
import { initialCameraState } from "./camera";
import { restoreCloudState } from "./cloud";
import { initialDimensionsState } from "./dimensions";
import { deselectPlanReducer, initialPlansState } from "./plans";
import { initialPopupsState } from "./popups";
import { initialPropertiesState } from "./properties";
import { initialSectionsState } from "./sections";
import { initialToolsState } from "./tools";

export interface IModelState {
  fileName: string | null;
  modelID: number | null;
  modelUUID: string | null;
  modelDetails: { [key: ModelID]: { modelUUID: string; fileName: string; modelCloudID?: string } };
  modelUploadedTime: string | null;
  isFirstLoad: boolean;
  isViewerDisplayed: boolean;
  isModelReadyToQuery: boolean;
  modelQueryTypes: string[] | null;
  modelQuerySelectedTypes: string[] | null;
  modelQuerySchema: { [key: string]: any } | null;
  modelQueryExportedAttributes: { [key: string]: any };
  isWaitingForModelSchema: boolean;
  isWaitingForModelQuery: boolean;
  modelQueryResult: any[] | null;
  modelQuerySelectGeneration: number;
  modelQueryResultGeneration: number;
}

export const initialModelState: IModelState = {
  fileName: null,
  modelID: null,
  modelUUID: null,
  modelDetails: {},
  modelUploadedTime: null,
  isFirstLoad: true,
  isViewerDisplayed: false,
  isModelReadyToQuery: false,
  modelQueryTypes: null,
  modelQuerySelectedTypes: null,
  modelQuerySchema: null,
  modelQueryExportedAttributes: {},
  isWaitingForModelSchema: false,
  isWaitingForModelQuery: false,
  modelQueryResult: null,
  modelQuerySelectGeneration: 1,
  modelQueryResultGeneration: 0,
};

export const loadIfcFile = createAsyncThunk(
  "ifcManager/loadFile",
  async (
    {
      file,
      url,
      directUrl,
      modelCloudId,
      isPartial,
    }: {
      file?: File;
      url?: string;
      directUrl?: string;
      modelCloudId?: string;
      isPartial?: boolean;
    },
    { dispatch, getState }
  ) => {
    const state = (getState() as RootState).ifcManager;
    dispatch(setSelectedModal(MODALS.LOADING));

    // no use resetting a clean state
    if (!state.isFirstLoad && !isPartial) {
      // note: possibly null if viewer fails to load because of WebGL
      if (viewerAPI()?.unloadAllSync) {
        await viewerAPI().unloadAllSync();
      }
    }

    /* wait the the Viewer to be imported and loaded */
    await dispatch(setIsViewerDisplayed());
    try {
      while ([VieweAPIState.UNINITIALIZED, VieweAPIState.LOADING].includes(ViewerSingleton.state)) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      if (ViewerSingleton.state == VieweAPIState.INIT_FAILED) {
        throw new Error("Failed to initialize viewerAPI");
      }

      const result = await viewerAPI().loadIfcFile(file, url, directUrl, modelCloudId, isPartial);
      let cloudState: Partial<IIfcMangerState> = {};
      if (result?.modelCloudId) {
        cloudState = (await restoreCloudState(result.modelCloudId)) ?? {};
      }

      const newModelDetails = {
        ...(isPartial && state.modelDetails),
      };
      if (result?.modelID) {
        newModelDetails[result.modelID] = {
          fileName: result.fileName,
          modelUUID: result.modelUUID,
          ...(modelCloudId && { modelCloudID: modelCloudId }),
        };
      }

      return {
        ...initialPopupsState,
        ...(!isPartial && {
          ...initialCameraState,
          ...initialDimensionsState,
          ...initialModelState,
          ...initialPlansState,
          ...initialPropertiesState,
          ...initialSectionsState,
          ...(state.activeTools[TOOLS.IDSEDITOR] == false && initialToolsState),
        }),
        isNavGizmoEnabled: true,
        ...cloudState,
        isFirstLoad: false,
        ...result,
        ...(isPartial && {
          plans: [...state.plans, ...(result?.plans ?? [])],
        }),
        modelDetails: newModelDetails,
        snackbarSeverity: SEVERITIES.SUCCESS,
        snackbarMessage: "IFC File loaded successfully!",
      };
    } catch (ex: any) {
      const baseRes: Partial<IIfcMangerState> = {
        ...initialPopupsState,
        ...(!isPartial && {
          ...initialCameraState,
          ...initialDimensionsState,
          ...initialModelState,
          ...initialPlansState,
          ...initialPropertiesState,
          ...initialSectionsState,
          ...(state.activeTools[TOOLS.IDSEDITOR] == false && initialToolsState),
        }),
        isFirstLoad: false,
      };

      console.error("loadIfcFile failed with:", ex, ex.message);
      if (ex?.message == "permission-denied") {
        return {
          ...baseRes,
          selectedModal: MODALS.UNAUTHDOMAIN,
        };
      }

      if (ex?.message == "Failed to initialize viewerAPI") {
        return {
          ...baseRes,
          snackbarSeverity: SEVERITIES.ERROR,
          snackbarMessage:
            "The viewer failed to initialize. Check you have WebGL enabled and refresh the page.",
        };
      }

      return {
        ...baseRes,
        snackbarSeverity: SEVERITIES.ERROR,
        snackbarMessage: "Unexpected error occurred while loading IFC file!",
      };
    }
  }
);

export const unloadOneReducer = (
  state: IIfcMangerState,
  action: PayloadAction<{ modelID: string }>
) => {
  const modelID = action.payload.modelID;
  viewerAPI()?.unloadOne(modelID);

  const newModelDetails: typeof state.modelDetails = {};

  for (const k of Object.keys(state.modelDetails)) {
    if (k != modelID) {
      newModelDetails[k] = state.modelDetails[k];
    }
  }

  deselectPlanReducer(state);
  state.modelDetails = newModelDetails;
  state.plans = state.plans.filter(x => x.modelID != modelID);

  if (String(state.propertiesModelUUID) == modelID) {
    state.propertiesModelUUID = null;
    state.propertiesExpressID = null;
  }
};

export const unloadAllReducer = (state: IIfcMangerState) => {
  // no use resetting a clean state
  if (state.isFirstLoad) return { ...state };

  // note: possibly null if viewer fails to load because of WebGL
  viewerAPI()?.unloadAll();

  return {
    ...state,
    ...initialCameraState,
    ...initialDimensionsState,
    ...initialModelState,
    ...initialPropertiesState,
    ...initialPlansState,
    ...initialSectionsState,
    ...initialToolsState,
    isFirstLoad: false,
  };
};

export const setFileNameReducer = (
  state: IIfcMangerState,
  action: PayloadAction<string | null>
) => {
  state.fileName = action.payload;
};

export const setIsViewerDisplayedReducer = (
  state: IIfcMangerState,
  action: PayloadAction<boolean | undefined>
) => {
  state.isViewerDisplayed = action?.payload ?? true;
};

export const setModelQueryExportedAttributesReducer = (
  state: IIfcMangerState,
  action: PayloadAction<{ [key: string]: any }>
) => {
  state.modelQueryExportedAttributes = action.payload;
  // a new querry will be triggered when this doesn't match modelQueryResultGeneration
  // => caching between tab changes/reopening if no selection changes
  state.modelQuerySelectGeneration = state.modelQuerySelectGeneration + 1;
};

export const setModelQuerySelectedTypesReducer = (
  state: IIfcMangerState,
  action: PayloadAction<string[]>
) => {
  state.modelQuerySelectedTypes = action.payload;
  state.modelQuerySelectGeneration = state.modelQuerySelectGeneration + 1;
};

export const exportModelQuery = createAsyncThunk(
  "ifcManager/exportModelQuery",
  async (_payload, { getState }) => {
    const state = (getState() as RootState).ifcManager;
    if (!state.modelQueryResult || state.modelQueryResult.length == 0) {
      return {
        snackbarSeverity: SEVERITIES.ERROR,
        snackbarMessage: "No query result to export",
      };
    }

    try {
      const saveData = (() => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        //@ts-ignore
        a.style = "display: none";
        return function (csvFile: any, fileName: string) {
          const blob = new Blob([csvFile], { type: "text/csv" }),
            url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        };
      })();

      const escapeCSVColumn = (text: string | undefined) => {
        console.log(text);
        return text ? `"${text.replace(/"/g, '""')}"` : "";
      };
      let csvContent = "";
      const exportedAttributes = state.modelQueryExportedAttributes;
      const exportedAttributesKeys = Object.keys(exportedAttributes).filter(
        x => exportedAttributes[x] != undefined
      );

      csvContent += exportedAttributesKeys.map(escapeCSVColumn).join(",") + "\r\n";
      state.modelQueryResult.forEach((row: any) => {
        csvContent +=
          exportedAttributesKeys
            .map(key => escapeCSVColumn(`${objectRecAccess(row, exportedAttributes[key])}`))
            .join(",") + "\r\n";
      });
      saveData(csvContent, `${state.fileName?.replace(".ifc", "")}_export.csv`);
    } catch (ex: any) {
      console.error(ex);
      return {
        snackbarSeverity: SEVERITIES.ERROR,
        snackbarMessage: "Could not export query result.",
      };
    }
  }
);

export const modelSelectors = {
  selectModelsDetails: (root: RootState) => root.ifcManager.modelDetails,
  selectModelId: (root: RootState) => root.ifcManager.modelID,
  selectModelUUID: (root: RootState) => root.ifcManager.modelUUID,
  selectIsFirstLoad: (root: RootState) => root.ifcManager.isFirstLoad,
  selectFileName: (root: RootState) => root.ifcManager.fileName,
  selectIsViewerDisplayed: (root: RootState) => root.ifcManager.isViewerDisplayed,
  selectIsModelReadyToQuery: (root: RootState) => root.ifcManager.isModelReadyToQuery,
  selectModelQueryTypes: (root: RootState) => root.ifcManager.modelQueryTypes,
  selectModelQuerySelectedTypes: (root: RootState) => root.ifcManager.modelQuerySelectedTypes,
  selectModelQuerySchema: (root: RootState) => root.ifcManager.modelQuerySchema,
  selectModelQueryResult: (root: RootState) => root.ifcManager.modelQueryResult,
  selectModelQueryExportedAttributes: (root: RootState) =>
    root.ifcManager.modelQueryExportedAttributes,
};

export const modelReducers = {
  unloadOne: unloadOneReducer,
  unloadAll: unloadAllReducer,
  setFileName: setFileNameReducer,
  setIsViewerDisplayed: setIsViewerDisplayedReducer,
  setModelQueryExportedAttributes: setModelQueryExportedAttributesReducer,
  setModelQuerySelectedTypes: setModelQuerySelectedTypesReducer,
};

export const modelThunks = {
  loadIfcFile,
  exportModelQuery,
};
