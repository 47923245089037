import { Typography } from "@mui/material";
import { TOOLS } from "app/common/types";
import { QuickstartDivider } from "app/components/quickstart/QuickstartDivider";
import { useAppDispatch } from "app/state/hooks";
import { setTool } from "app/state/slices/ifcManagerSlice";
import { useEffect } from "react";

export default function QuickstartSection() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTool({ selectedTool: TOOLS.SECTION }));
  }, []);

  return (
    <>
      <QuickstartDivider />
      <Typography sx={{ maxWidth: "40rem", textAlign: "justify" }}>
        The Section Tool allows you to cut through your IFC models for better visualization of
        internal structures. Whether you’re inspecting details or creating a custom view, this
        feature makes it easy to explore complex models.
        <br />
        <br /> You can quickly add multiple sections and toggle their visibility on and off, giving
        you full control over what you see and how you present your models.
      </Typography>
      <QuickstartDivider />
      <Typography sx={{ width: "100%", textAlign: "center" }}>
        Click <b>Next</b> to explore the Dimension Tool, where you can start measuring key elements
        in your models.
      </Typography>
    </>
  );
}
