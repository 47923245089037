import { Divider } from "@mui/material";
import theme from "app/theme";

export function QuickstartDivider() {
  return (
    <Divider
      sx={{ width: "100%", marginTop: theme.sppx.spaciousH, marginBottom: theme.sppx.spaciousH }}
    />
  );
}
