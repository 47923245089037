import { Box } from "@mui/material";
import { TOOLS } from "app/common/types";
import { useAppSelector } from "app/state/hooks";
import {
  selectIsLeftPanelOpen,
  selectIsToolActive,
  selectModelId,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import { memo } from "react";
import { useSelector } from "react-redux";
import { useIsQuickstart } from "app/state/contexts";

import NoModelLoaded from "../NoModelLoaded";
import StyledDrawer from "../StyledDrawer";
import HelpDrawer from "../help/HelpDrawer";
import ObjectTreeSidebar from "./ObjectTreeSidebar";
import ToolsBar from "./ToolsBar";
import DimensionToolSidebar from "./dimensionTool/DimensionToolSidebar";
import IDSEditorSidebar from "./idsEditor/IDSEditorSidebar";
import PlanManagerSidebar from "./plan-manager/PlanManagerSidebar";
import SectionToolSidebar from "./section-tool/SectionToolSidebar";

// const LEFT_NAV_TOOLS: Tool[] = [
//   TOOLS.DIMENSION,
//   TOOLS.HELP,
//   TOOLS.SECTION,
//   TOOLS.PLAN_MANAGER,
// ];

function Content() {
  const isSectionSelected = useAppSelector(selectIsToolActive(TOOLS.SECTION));
  const isDimensionSelected = useAppSelector(selectIsToolActive(TOOLS.DIMENSION));
  const isHelpSelected = useAppSelector(selectIsToolActive(TOOLS.HELP));
  const modelId = useSelector(selectModelId);

  return (
    <>
      {isSectionSelected && (modelId ? <SectionToolSidebar /> : <NoModelLoaded />)}
      {isDimensionSelected && (modelId ? <DimensionToolSidebar /> : <NoModelLoaded />)}
      <PlanManagerSidebar />
      <ObjectTreeSidebar />
      <IDSEditorSidebar />
      {isHelpSelected && <HelpDrawer />}
    </>
  );
}
const MemoContent = memo(Content);

function ContentContainer() {
  const isVisible = useAppSelector(selectIsLeftPanelOpen);

  return (
    <StyledDrawer
      variant="permanent"
      open={isVisible}
      anchor="left"
      PaperProps={{
        sx: {
          mt: theme.sppx.headerHeight,
          ml: theme.sppx.sidebarWidth,
          width: isVisible ? theme.sppx.sidepanelWidth : 0,
          height: `calc(100vh - ${theme.sppx.headerHeight} - ${theme.sppx.footerHeight})`,
          maxHeight: `calc(100vh - ${theme.sppx.headerHeight} - ${theme.sppx.footerHeight})`,
          overflowY: "auto",
          boxSizing: "border-box",
          borderRight: isVisible ? "1px solid #ddd" : "none",
        },
      }}
    >
      <MemoContent />
    </StyledDrawer>
  );
}
const MemoContentContainer = memo(ContentContainer);

export default function ToolsSideBar() {
  const isQuickstart = useIsQuickstart();

  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
      }}
    >
      <ToolsBar />
      {!isQuickstart && <MemoContentContainer />}
    </Box>
  );
}
