import CategoryIcon from "@mui/icons-material/Category";
import { Box, Tooltip, TypographyProps } from "@mui/material";
import { TOOLS } from "app/common/types";
import { BoxDynCol } from "app/components/common/BoxDynCol";
import { BoxPaddedCenterCol } from "app/components/common/BoxPaddedCenterCol";
import { useAppDispatch, useAppSelector } from "app/state/hooks";
import {
  selectIsToolActive,
  selectModelId,
  selectPropertiesItemName,
  toggleTool,
} from "app/state/slices/ifcManagerSlice";
import theme from "app/theme";
import { forwardRef, ForwardedRef } from "react";
import { useIsQuickstart } from "app/state/contexts";
import { CircledIconButton } from "app/components/common/CircledIconButton";

import NoModelLoaded from "../NoModelLoaded";
import StyledDrawer from "../StyledDrawer";
import SidebarHeader from "../ToolsSideBar/SidebarHeader";
import { PROPERTIES_HELP_CONTENT } from "../help/HelpTexts";
import * as S from "./PropertiesSidebar.style";
import { PropertiesSidebarAnchor } from "./PropertiesSidebarAnchor";
import PropertiesTabs from "./PropertiesTabs";

export const PropertiesSidebarItemName = forwardRef(function PropertiesSidebarItemName(
  props: TypographyProps,
  ref?: ForwardedRef<HTMLSpanElement>
) {
  const propertiesItemName = useAppSelector(selectPropertiesItemName);

  return (
    <S.ObjectTitle {...props} ref={ref}>
      <strong>{propertiesItemName}</strong>
    </S.ObjectTitle>
  );
});

export default function PropertiesSidebar() {
  const dispatch = useAppDispatch();
  const isQuickstart = useIsQuickstart();

  const isActive = useAppSelector(selectIsToolActive(TOOLS.PROPERTIES));
  const isOpen = !isQuickstart && isActive;

  const modelId = useAppSelector(selectModelId);

  function Content() {
    if (modelId === null) {
      return <NoModelLoaded />;
    }

    return (
      <BoxDynCol>
        <BoxPaddedCenterCol>
          <Tooltip title="Selected object name" placement="left">
            <PropertiesSidebarItemName />
          </Tooltip>
        </BoxPaddedCenterCol>
        <PropertiesTabs />
      </BoxDynCol>
    );
  }

  const color = isActive ? theme.palette.primary.main : theme.palette.inactiveGrey;

  return (
    <StyledDrawer
      variant="permanent"
      open={isOpen}
      anchor="right"
      PaperProps={{
        sx: {
          mt: theme.sppx.headerHeight,
          width: isOpen ? theme.sppx.sidepanelWidth : theme.sppx.sidebarWidth,
          height: `calc(100vh - ${theme.sppx.headerHeight} - ${theme.sppx.footerHeight})`,
          maxHeight: `calc(100vh - ${theme.sppx.headerHeight} - ${theme.sppx.footerHeight})`,
        },
      }}
      sx={{
        [theme.breakpoints.down("sm")]: { display: "none" },
        "& .MuiDrawer-root": {
          overflow: "visible",
          overflowX: "visible",
        },
        "& .MuiPaper-root": {
          overflow: "visible",
          overflowX: "visible",
        },
      }}
    >
      <Box
        sx={{
          display: isOpen ? "flex" : "none",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <SidebarHeader
          placedOnLeftSidebar={false}
          title="Property sets"
          selectedTool={TOOLS.PROPERTIES}
          HelpContent={PROPERTIES_HELP_CONTENT}
        />
        <Content />
      </Box>

      {!isOpen && (
        // has to be conditional because tooltips complain about display none even on the parrent
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: theme.sppx.compact2V,
          }}
        >
          <Tooltip placement="left" arrow title="Toggle property sets">
            <CircledIconButton
              isCircled={isActive}
              {...(isQuickstart && { href: "/properties-tool" })}
              onClick={() =>
                !isQuickstart && dispatch(toggleTool({ selectedTool: TOOLS.PROPERTIES }))
              }
              sx={{ color }}
            >
              <CategoryIcon sx={{ color }} />
            </CircledIconButton>
          </Tooltip>
        </Box>
      )}
      <PropertiesSidebarAnchor />
    </StyledDrawer>
  );
}
