import { Typography } from "@mui/material";
import { TOOLS } from "app/common/types";
import { QuickstartDivider } from "app/components/quickstart/QuickstartDivider";
import { useAppDispatch } from "app/state/hooks";
import { setTool } from "app/state/slices/ifcManagerSlice";
import { useEffect } from "react";

export default function QuickstartIDS() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTool({ selectedTool: TOOLS.IDSEDITOR }));
  }, []);

  return (
    <>
      <QuickstartDivider />
      <Typography sx={{ maxWidth: "40rem", textAlign: "justify" }}>
        With our IDS tool, you can create, edit, import, and export Information Delivery
        Specification (IDS) files directly within the platform—no need to write XML! Our visual
        editor makes it simple to define project requirements and standards. Once your IDS files are
        ready, you can validate your IFC models against them in just a few clicks, ensuring your
        models meet industry standards and project specifications.
        <br />
        <br /> This powerful tool streamlines model quality control and compliance, making it a
        must-have for anyone working with BIM standards.
      </Typography>
      <QuickstartDivider />
      <Typography sx={{ width: "100%", textAlign: "center" }}>
        Click <b>Next</b> to explore the Properties tool, where you can dive into the details of any
        object in your model.
      </Typography>
    </>
  );
}
