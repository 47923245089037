import { BoxProps, Button } from "@mui/material";
import { BoxRow } from "app/components/common/BoxRow";
import { quickstartMeta, quickstartOrder } from "app/pages/quickstart/quickstartMeta";
import theme from "app/theme";

export function QuickstartNextPrev({
  orderIndex,
  sx,
  ...otherProps
}: BoxProps & { orderIndex: number }) {
  const isFirst = orderIndex == 0;
  const isLast = orderIndex == quickstartOrder.length - 1;

  return (
    <BoxRow
      sx={{ width: "100%", justifyContent: "center", gap: theme.sppx.spaciousH, ...sx }}
      {...otherProps}
    >
      {!isFirst && (
        <Button variant="outlined" href={quickstartMeta[quickstartOrder[orderIndex - 1]].location}>
          Prev
        </Button>
      )}
      {!isLast && (
        <Button variant="contained" href={quickstartMeta[quickstartOrder[orderIndex + 1]].location}>
          Next
        </Button>
      )}
    </BoxRow>
  );
}
