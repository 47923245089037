import { Typography } from "@mui/material";
import { TOOLS } from "app/common/types";
import { QuickstartDivider } from "app/components/quickstart/QuickstartDivider";
import { useAppDispatch } from "app/state/hooks";
import { setTool } from "app/state/slices/ifcManagerSlice";
import { useEffect } from "react";

export default function QuickstartObjectTree() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTool({ selectedTool: TOOLS.OBJECTTREE }));
  }, []);

  return (
    <>
      <QuickstartDivider />
      <Typography sx={{ maxWidth: "40rem", textAlign: "justify" }}>
        The Object Tree allows you to explore and manage your IFC model&apos;s hierarchical
        structure with ease. Quickly find and select specific objects or groups of objects, and
        toggle their visibility for a more streamlined view. <br />
        <br />
        This tool helps you stay organized and navigate complex models efficiently, whether
        you&apos;re inspecting individual elements or managing entire sections.
      </Typography>
      <QuickstartDivider />
      <Typography sx={{ width: "100%", textAlign: "center" }}>
        Click <b>Next</b> to learn about the Properties feature, where you can dive into the details
        of any object in your model.
      </Typography>
    </>
  );
}
