export type ModelID = string;
export type CheckboxStateType = Record<string, boolean>;

export type PropertiesMappingType = Record<number, string>;

export type Point = {
  x: number;
  y: number;
  z: number;
};

export type SpatialPoint = {
  x: number;
  y: number;
  z: number;
};

export type DimensionId = number;

export type Dimension = {
  id: DimensionId;
  isHighlighted: boolean;
  isVisible: boolean;
  length: number; // meters
  startPoint: number[];
  endPoint: number[];
};

export type Plan = {
  modelID: ModelID;
  id: string;
  name: string;
};

export type SectionId = number;

export type Section = {
  id: SectionId;
  isVisibilityLocked: boolean;
  isEditable: boolean;
  name: string;
  origin: number[];
  normal: number[];
};

// - why add these interfaces?
// - there are multiple versions in openBIM depending on ifc standard
// we use just a common subset
export type IFCQuantity = {
  value: number; // = expressID
  type: number;
};
export type IFCProperty = {
  value: any;
  type: number;
};

// property types

type CommonProps = { value: number | string; type: number };

type ChildProperty = {
  type: number;
  value: IProperty;
};

export type PropertyValue = {
  name: string;
  values: any[];
};

export type QuantityValue = {
  name: string;
  values: any[];
};

export interface IProperty {
  Description: string | null;
  expressID: number;
  HasProperties?: Array<ChildProperty>;
  propertyValues?: PropertyValue[];
  Quantities?: Array<IFCQuantity>;
  quantityValues?: QuantityValue[];
  Name: CommonProps;
  ObjectPlacement: CommonProps;
  ObjectType: CommonProps;
  OverallHeight: CommonProps;
  OverallWidth: CommonProps;
  OwnerHistory: CommonProps;
  Representation: CommonProps;
  Tag: CommonProps;
  type: number;
}

export type MUIAutocompleteOnChangeValue = {
  label: string | number;
  key: number;
  value: number;
  HasProperties: ChildProperty[] | undefined;
  Quantities: IFCQuantity[] | undefined;
} | null;

export type ReactMouseEvent = React.MouseEvent<HTMLElement, MouseEvent>;

export enum TOOLS {
  DIMENSION = "DIMENSION",
  PROPERTIES = "PROPERTIES",
  SECTION = "SECTION",
  PLAN_MANAGER = "PLAN_MANAGER",
  SPATIAL = "SPATIAL",
  OBJECTTREE = "OBJECTTREE",
  IDSEDITOR = "IDSEDITOR",
  HELP = "HELP",
  QUERY = "QUERY",
}

export type Tool = TOOLS | null;

export enum QUICKSTART_SECTIONS {
  HOME = "HOME",
  SECTION = "SECTION",
  DIMENSION = "DIMENSION",
  PLAN_MANAGER = "PLAN_MANAGER",
  QUERY = "QUERY",
  OBJECTTREE = "OBJECTTREE",
  PROPERTIES = "PROPERTIES",
  IDSEDITOR = "IDSEDITOR",
}

export type SavedModel = {
  id: string;
  name: string;
  size: number;
};

export enum MODALS {
  REGISTER = "REGISTER",
  ACCOUNT = "ACCOUNT",
  CLOUDPICKER = "CLOUDPICKER",
  LOGIN = "LOGIN",
  LOADING = "LOADING",
  UNAUTHDOMAIN = "UNAUTHORIZEDDOMAIN",
  POLICY = "POLICY",
  OPENAGREEMENT = "OPENAGREEMENT",
}

export enum POLICIES {
  PRIVACY_FREE = "PRIVACY_FREE",
  PRIVACY_OPEN = "PRIVACY_OPEN",
  TOS_FREE = "TOS_FREE",
  TOS_OPEN = "TOS_OPEN",
  COOKIES_FREE = "COOKIES_FREE",
  COOKIES_OPEN = "COOKIES_OPEN",
  ACCEPTABLE_USE_FREE = "ACCEPTABLE_USE_FREE",
  ACCEPTABLE_USE_OPEN = "ACCEPTABLE_USE_OPEN",
}

export enum INPUTTYPES {
  EMAIL = "email",
  NAME = "name",
  PASSWORD = "password",
  NEWPASSWORD = "newPassword",
  NEWPASSWORDCONFIRMATION = "newPasswordConfirmation",
}

export const inputTypeProps: { [key: string]: any } = {
  email: {
    label: "Email",
    name: "email",
    autoComplete: "email",
  },
  name: {
    label: "Name",
    name: "name",
    autoComplete: "name",
  },
  password: {
    label: "Password",
    name: "password",
    type: "password",
    autoComplete: "password",
  },
  newPassword: {
    label: "New Password",
    name: "newPassword",
    type: "password",
    autoComplete: "new-password",
  },
  newPasswordConfirmation: {
    label: "New Password Confirmation",
    name: "newPasswordConfirmation",
    type: "password",
    autoComplete: "new-password",
  },
};

export enum SEVERITIES {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export enum AUTHPROVIDERS {
  MICROSOFT = "microsoft.com",
  GOOGLE = "google.com",
  EMAIL = "email",
}

export type ExpressID = string;
export type SpatialNode = {
  name: string;
  id: string;
  children?: SpatialNode[];
  metadata: {
    type: string;
    modelUUID: string;
    expressID: string;
    expressIDs: number[];
    isVisible: boolean;
    fragmentIDs: string[];
  };
};

export type SpatialNodesMap = { [key: ModelID]: { [key: ExpressID]: SpatialNode } };

export type AutodeskEntityTypes = "hubs" | "projects" | "folders" | "files" | "items";

export type AutodeskEntity = {
  displayName?: string;
  name?: string;
  type?: AutodeskEntityTypes;
  id?: string;
  hubId?: string;
  projectId?: string;
  folderId?: string;
  children: string[]; // ids list
};

export type AutodeskEntityHandle = {
  type?: AutodeskEntityTypes;
  id?: string;
  hubId?: string;
  projectId?: string;
  folderId?: string;
};

export const getAutodeskEntityHandle = (entity: AutodeskEntityHandle | AutodeskEntity) => {
  return {
    type: entity.type,
    id: entity.id,
    hubId: entity.hubId,
    projectId: entity.projectId,
    folderId: entity.folderId,
  } as AutodeskEntityHandle;
};
