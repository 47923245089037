import { Typography } from "@mui/material";
import { TOOLS } from "app/common/types";
import { QuickstartDivider } from "app/components/quickstart/QuickstartDivider";
import { useAppDispatch } from "app/state/hooks";
import { setTool } from "app/state/slices/ifcManagerSlice";
import { useEffect } from "react";

export default function QuickstartPlans() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTool({ selectedTool: TOOLS.PLAN_MANAGER }));
  }, []);

  return (
    <>
      <QuickstartDivider />
      <Typography sx={{ maxWidth: "40rem", textAlign: "justify" }}>
        The Plan Tool allows you to switch between 3D and 2D views, making it easy to focus on
        specific floor plans or sections of your IFC model. With this tool, you can generate clear,
        precise 2D plans directly from your model for easier review and navigation.
        <br />
        <br /> Toggle between different plans or layers, and streamline your project management with
        intuitive 2D visualization.
      </Typography>
      <QuickstartDivider />
      <Typography sx={{ width: "100%", textAlign: "center" }}>
        Click <b>Next</b> to explore the Data Extraction tool, where you can export key data to
        Excel or CSV files.
      </Typography>
    </>
  );
}
