import { Typography, Paper } from "@mui/material";
import { TOOLS } from "app/common/types";
import { QuickstartDivider } from "app/components/quickstart/QuickstartDivider";
import { useAppDispatch } from "app/state/hooks";
import { setTool } from "app/state/slices/ifcManagerSlice";
import { useEffect } from "react";
// import FeatureDimensionImage from "app/static/images/feature_dimension.png";
import theme from "app/theme";

export default function QuickstartDimension() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTool({ selectedTool: TOOLS.DIMENSION }));
  }, []);

  return (
    <>
      <QuickstartDivider />
      <Typography sx={{ maxWidth: "40rem", textAlign: "justify" }}>
        The Dimension Tool allows you to measure distances and dimensions directly within your IFC
        model. You can easily add multiple dimensions and toggle their visibility on and off, giving
        you full control over the measurements displayed.
      </Typography>
      <Paper
        sx={{
          marginTop: theme.sppx.spaciousH,
          width: "100%",
          maxWidth: "50rem",
        }}
        elevation={5}
      >
        <div
          style={{
            width: "100%",
            height: "0",
            position: "relative",
            paddingBottom: "calc(75.23255814% + 32px)",
            boxSizing: "border-box",
            overflow: "hidden",
            zIndex: "99999",
          }}
        >
          <iframe
            src="https://www.guidejar.com/embed/a731565d-05e3-4544-b49a-1fa2d65bf91c?type=1&controls=on"
            style={{
              position: "absolute",
            }}
            width="100%"
            height="100%"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </div>
      </Paper>

      <QuickstartDivider />
      <Typography sx={{ width: "100%", textAlign: "center" }}>
        Click <b>Next</b> to discover the Plan Tool, which lets you view and manage 2D plans of your
        models.
      </Typography>
    </>
  );
}
