import { QUICKSTART_SECTIONS } from "app/common/types";

import QuickstartHome from "./QuickstartHome";
import QuickstartSection from "./QuickstartSection";
import QuickstartDimension from "./QuickstartDimension";
import QuickstartPlans from "./QuickstartPlans";
import QuickstartQuery from "./QuickstartQuery";
import QuickstartObjectTree from "./QuickstartObjectTree";
import QuickstartIDS from "./QuickstartIDS";
import QuickstartProperties from "./QuickstartProperties";

export const quickstartOrder = [
  QUICKSTART_SECTIONS.HOME,
  QUICKSTART_SECTIONS.SECTION,
  QUICKSTART_SECTIONS.DIMENSION,
  QUICKSTART_SECTIONS.PLAN_MANAGER,
  QUICKSTART_SECTIONS.QUERY,
  QUICKSTART_SECTIONS.OBJECTTREE,
  QUICKSTART_SECTIONS.IDSEDITOR,
  QUICKSTART_SECTIONS.PROPERTIES,
];

export const quickstartMeta: Record<
  QUICKSTART_SECTIONS,
  {
    location: string;
    component: () => JSX.Element;
    title: string;
    taglineBold: string;
    taglineHasNewline?: boolean;
    tagline: string;
  }
> = {
  [QUICKSTART_SECTIONS.HOME]: {
    location: "/quickstart",
    component: QuickstartHome,
    title: "Product Overview",
    taglineBold: "Welcome to Sortdesk:",
    taglineHasNewline: true,
    tagline: "Your Free, Web-Based IFC Viewer and IDS Editor",
  },
  [QUICKSTART_SECTIONS.SECTION]: {
    location: "/section-tool",
    component: QuickstartSection,
    title: "Section Tool",
    taglineBold: "Section Tool: ",
    tagline: "Visualize Your Models with Precision",
  },
  [QUICKSTART_SECTIONS.DIMENSION]: {
    location: "/dimension-tool",
    component: QuickstartDimension,
    title: "Dimension Tool",
    taglineBold: "Dimension Tool: ",
    tagline: "Measure with Ease",
  },
  [QUICKSTART_SECTIONS.PLAN_MANAGER]: {
    location: "/plans-tool",
    component: QuickstartPlans,
    title: "Plan Tool",
    taglineBold: "Plan Tool: ",
    tagline: "View Your Models in 2D",
  },
  [QUICKSTART_SECTIONS.QUERY]: {
    location: "/query-tool",
    component: QuickstartQuery,
    title: "Data Extraction Tool",
    taglineBold: "Data Extraction: ",
    tagline: "Export IFC Data to Excel or CSV",
  },
  [QUICKSTART_SECTIONS.OBJECTTREE]: {
    location: "/object-tree-tool",
    component: QuickstartObjectTree,
    title: "Object Tree",
    taglineBold: "Object Tree: ",
    tagline: "Navigate Your Model Hierarchies",
  },
  [QUICKSTART_SECTIONS.IDSEDITOR]: {
    location: "/ids-tool",
    component: QuickstartIDS,
    title: "IDS",
    taglineBold: "IDS: ",
    tagline: "Full Online IDS Editor and Model Quality Checker",
  },
  [QUICKSTART_SECTIONS.PROPERTIES]: {
    location: "/properties-tool",
    component: QuickstartProperties,
    title: "Properties",
    taglineBold: "Properties: ",
    tagline: "Access Detailed Object Information",
  },
};
