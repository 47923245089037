import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import DescriptionIcon from "@mui/icons-material/Description";
import HelpOutlineRounded from "@mui/icons-material/HelpOutlineRounded";
import StraightenIcon from "@mui/icons-material/Straighten";
import { Badge } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { TOOLS } from "app/common/types";
import { CircledIconButton } from "app/components/common/CircledIconButton";
import { useAppDispatch } from "app/state/hooks";
import {
  selectActiveTools,
  selectIsMeasurementCreationActive,
  selectIsSectionCreationActive,
  selectSelectedTools,
  toggleTool,
} from "app/state/slices/ifcManagerSlice";
import theme, { styled } from "app/theme";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useIsQuickstart } from "app/state/contexts";

import * as S from "./ToolsSideBar.style";

interface ITool {
  tool: TOOLS;
  tooltip: string;
  icon: JSX.Element;
  quickstartLink: string;
}

const toolButtons: Array<ITool> = [
  {
    tool: TOOLS.SECTION,
    tooltip: "Toggle section tool",
    icon: <ContentCutIcon />,
    quickstartLink: "/section-tool",
  },
  {
    tool: TOOLS.DIMENSION,
    tooltip: "Toggle dimension tool",
    icon: <StraightenIcon />,
    quickstartLink: "/dimension-tool",
  },
  {
    tool: TOOLS.PLAN_MANAGER,
    tooltip: "Toggle plan manager",
    icon: <DescriptionIcon />,
    quickstartLink: "/plans-tool",
  },
  {
    tool: TOOLS.QUERY,
    tooltip: "Export a Table",
    icon: <CalendarViewMonthIcon />,
    quickstartLink: "/query-tool",
  },
  {
    tool: TOOLS.OBJECTTREE,
    tooltip: "Toggle Object Tree",
    icon: <AccountTreeOutlinedIcon />,
    quickstartLink: "/object-tree-tool",
  },
  {
    tool: TOOLS.IDSEDITOR,
    tooltip: "Toggle IDS Editor",
    icon: <ChecklistIcon />,
    quickstartLink: "/ids-tool",
  },
  {
    tool: TOOLS.HELP,
    tooltip: "Show help",
    icon: <HelpOutlineRounded />,
    quickstartLink: "/quickstart",
  },
];

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    //@ts-ignore
    backgroundColor: theme.palette.notificationBadge.main,
    //@ts-ignore
    color: theme.palette.notificationBadge.contrastText,
  },
  [`& .${tooltipClasses.arrow}`]: {
    //@ts-ignore
    color: theme.palette.notificationBadge.main,
  },
}));

export default function ToolsBar() {
  const dispatch = useAppDispatch();

  const selectedTools = useSelector(selectSelectedTools);
  const activeTools = useSelector(selectActiveTools);
  const anyActiveTools = Object.values(activeTools).some(x => x);
  const isSectionCreationActive = useSelector(selectIsSectionCreationActive);
  const isMeasurementCreationActive = useSelector(selectIsMeasurementCreationActive);
  const hasAccessedIDSTool = localStorage.getItem("hasAccessedIDSTool") === "true";

  const isQuickstart = useIsQuickstart();

  const [isNEWTooltipOpen, setIsNEWTooltipOpen] = useState(true);

  const toolButtonComponents = toolButtons.map(({ tooltip, icon, tool, quickstartLink }) => {
    const isSelected = selectedTools[tool] == true;
    const isActive = activeTools[tool] == true;
    const isCreating =
      (tool === TOOLS.SECTION && isSectionCreationActive) ||
      (tool === TOOLS.DIMENSION && isMeasurementCreationActive);

    // useful for when user hides the tool to better see the model
    const color = isActive || isCreating ? theme.palette.primary.main : theme.palette.inactiveGrey;
    return (
      <S.StyledTooltip
        key={tool}
        placement="right"
        arrow
        title={tooltip}
        sx={{ color }}
        onOpen={() => tool == TOOLS.IDSEDITOR && setIsNEWTooltipOpen(false)}
        onClose={() => tool == TOOLS.IDSEDITOR && setIsNEWTooltipOpen(true)}
      >
        {tool == TOOLS.IDSEDITOR && !hasAccessedIDSTool ? (
          <Badge
            variant="dot"
            //@ts-ignore
            color="notificationBadge"
            invisible={!anyActiveTools || isActive}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            overlap="circular"
          >
            <LightTooltip
              title="NEW"
              arrow
              open={!anyActiveTools && isNEWTooltipOpen}
              placement="right"
              sx={{
                [theme.breakpoints.down("sm")]: { display: "none" },
              }}
            >
              <CircledIconButton
                isCircled={isSelected}
                {...(isQuickstart && { href: quickstartLink })}
                onClick={() => {
                  localStorage.setItem("hasAccessedIDSTool", "true");
                  !isQuickstart && dispatch(toggleTool({ selectedTool: tool }));
                }}
                sx={{ color }}
              >
                {icon}
              </CircledIconButton>
            </LightTooltip>
          </Badge>
        ) : (
          <CircledIconButton
            isCircled={isSelected}
            {...(isQuickstart && { href: quickstartLink })}
            onClick={() => !isQuickstart && dispatch(toggleTool({ selectedTool: tool }))}
          >
            {icon}
          </CircledIconButton>
        )}
      </S.StyledTooltip>
    );
  });

  return (
    <S.ToolsContainer
      sx={{
        [theme.breakpoints.down("sm")]: { display: "none" },
        gap: theme.sppx.gapTiny,
        borderRight: "1px solid #ddd",
      }}
    >
      {toolButtonComponents}
    </S.ToolsContainer>
  );
}
